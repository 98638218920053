/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { YukkApi } from '../../../service/yukkapi.service';
import { RoutingService } from 'src/app/service/routing.service';
import { ChartLegendComponent } from './chart-legend/chart-legend.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfigService } from '../../../service/config.service';
import { StockChart } from 'angular-highcharts';
import { ColorPipe } from '../../../pipe/color.pipe';
import { ColorScorePipe } from '../../../pipe/color-score.pipe';
import { ColorScore2Pipe } from '../../../pipe/color-score2.pipe';
import { AuthService } from '../../../service/auth.service';
import * as moment from 'moment';
import * as d3 from 'd3';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import * as FileSaver from 'file-saver';
import domtoimage from 'dom-to-image';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';

/**
 * In this component we use the {@link https://www.highcharts.com/stock/demo | Highstock Chart} the yAxis are two: one for the volume and one for the sentiment but the xAxis is generated by another stock chart to allow the navigation in time
 */

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-chart',
  templateUrl: './news-chart.component.html',
  styleUrls: ['./news-chart.component.scss'],
})
export class NewsChartComponent {
  h2c: any = html2canvas;
  titolo: any;
  loading: boolean;
  options: object;
  options2: object;
  home: any = [];
  serie_multi: any;
  stockChart: StockChart;
  stockChartRef: any;
  izoom: StockChart;
  izoomRef: any;
  theme: string;
  params: any;
  myparams: any;
  chartinit: boolean;
  previousValue: any;
  previousValue2: any;
  timeframe = '7';
  period1: number;
  period2: number;
  ioption: any;
  project: any;
  setup: any;
  annotations: any;
  keyd: string;
  mynotes: any;
  volume_ts: any;
  quotient_ts: any;
  quotient_ts2: any;
  score_ts: any;
  score_ts_E: any;
  score_ts_S: any;
  score_ts_G: any;
  scoreE_last: any;
  scoreS_last: any;
  scoreG_last: any;
  prices: any;
  fully = {
    max: null,
    min: null,
  };
  instart: boolean;
  iizoom: string;
  currency: string;
  fromSearchQuery: boolean;
  fromNewsAudit: boolean;
  fromScanner: boolean;
  fromPortfolio: boolean;
  fromWidget: boolean;
  fromScoreFocus: boolean;
  scoreOnChart: any;
  esgSetup: boolean;
  isCompanyTag: boolean;
  isPneTag: boolean;
  events: any;
  eventsAnalytics: any;
  ievents: any;
  nodata: boolean;
  showAverage: boolean;
  showAverage2: boolean;
  showVolumeInLegend: boolean;
  showEventsInLegend: boolean;
  showSentiment1InLegend: boolean;
  showSentiment2InLegend: boolean;
  showScoreInLegend: boolean;
  showScoreEInLegend: boolean;
  showScoreSInLegend: boolean;
  showScoreGInLegend: boolean;
  showPriceInLegend: boolean;

  //additional param for llm chat charts widgets path: ../../../project/llm/chat-charts/chat-charts.component.ts
  customScore: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    private dialog: MatDialog,
    private colorpipe: ColorPipe,
    private colorpipeScore: ColorScorePipe,
    private colorpipeScore2: ColorScore2Pipe,
    public auth: AuthService,
    public config: ConfigService,
  ) {
    this.customScore = this.auth.scorelabSettings.defaultScore;

    this.project = this.config.appConfig.routing.project;
    this.setup = this.config.appConfig.setup;

    this.showVolumeInLegend = true;
    this.showEventsInLegend = true;
    this.showSentiment1InLegend = false;
    this.showSentiment2InLegend = false;
    this.showScoreInLegend = true;
    this.showScoreEInLegend = false;
    this.showScoreSInLegend = false;
    this.showScoreGInLegend = false;
    this.showPriceInLegend = false;

    this.route.queryParams.subscribe((params) => {
      this.params = JSON.parse(JSON.stringify(params));

      if (this.params.custom_score) {
        this.customScore = this.params.custom_score;
      } else {
        this.customScore = this.auth.scorelabSettings.defaultScore;
      }

      this.showAverage = false;
      this.showAverage2 = false;
      this.fromSearchQuery = this.routing.isQuery() || this.routing.isSearch();
      this.fromNewsAudit = this.routing.isNewsaudit();
      this.fromScanner = this.routing.isScanner() || this.params.custom_score;
      this.fromPortfolio = this.routing.isFolio() && !params.portfolioId;
      this.fromWidget = this.routing.isWidget();
      this.fromScoreFocus =
        routing.isChart() &&
        (routing.isFolio() || routing.isMarket()) &&
        this.params.score;
      this.esgSetup =
        (!this.fromScoreFocus &&
          ((!this.fromWidget && this.customScore === 'esg') ||
            (this.fromWidget &&
              this.customScore === 'esg' &&
              !params.score_type) ||
            (this.fromWidget && params.score_type === 'esg'))) ||
        (this.fromScoreFocus && this.params.score === 'esg');

      if (this.fromScoreFocus) {
        // this.params.time = this.auth.scoreFocusedTimeframe;
        this.scoreOnChart = this.params.score;
      } else {
        this.scoreOnChart = this.customScore;
      }

      if (this.params.tag && this.params.tag.split(':')[1] === 'company') {
        this.isCompanyTag = true;
        this.isPneTag = false;
      } else if (this.params.tag && this.params.tag.split(':')[1] === 'pne') {
        this.isCompanyTag = false;
        this.isPneTag = true;
      } else {
        this.isCompanyTag = false;
        this.isPneTag = false;
      }

      this.keyd = params.type + ':' + params.id;
      if (this.fromScanner) {
        // this.params.time = '90';
      }
      if (this.params.fully) {
        this.fully = { min: 0, max: 1 };
      } else {
        this.fully = { min: null, max: null };
      }
      if (params.theme) {
        this.theme = params.theme;
      } else if (this.config.appConfig.setup.useStorage) {
        this.theme = localStorage.getItem('theme');
      } else {
        this.theme = 'dark';
      }
      if (this.theme === 'yukka') {
        this.theme = 'dark';
      }
      this.ievents = [];
      this.ioption = {
        color: this.theme === 'dark' ? '255, 255, 255' : '0, 0, 0',
        colorAudit: '94, 107, 120',
        yAxis: {
          score: {
            gridLineWidth: this.fromScanner ? 0 : 2,
            crosshair: null,
          },
        },
        isprice: false,
        exporting: false,
      };

      if (!this.instart) {
        this.instart = true;
        this.initZoom();
      } else {
        if (this.iizoom !== this.params.time) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.params.time
            ? (this.timeframe = this.params.time)
            : (this.timeframe = '7');
          this.iizoom = this.params.time;
          this.initZoom();
        }
      }
      const refreshParams = [
        'type',
        'id',
        'isin',
        'time',
        'lang',
        'feed',
        'categories',
        'continents',
        'countries',
        'factuality',
        'temporality',
        'ranks',
        'period1',
        'period2',
        'update',
        'theme',
        'fullscreen',
        'annotation',
        'fully',
        'score_type',
        'score',
      ];
      if (this.routing.isScanner()) {
        refreshParams.push('tag');
        refreshParams.push('scoreUid');
      }
      if (!this.fromNewsAudit && !this.fromScanner) {
        refreshParams.push('eventid');
      }
      const redrawParams = [
        'newsday',
        'eventid',
        'tag',
        'newstype',
        'sort',
        'news',
      ];
      if (this.routing.reFresh(this.params, this.previousValue, redrawParams)) {
        if (this.options) {
          this.options['chart']['animation'] = false;
          this.options['plotOptions']['series']['animation'] = false;
          this.stockChart = new StockChart(this.options);
        }
      }
      const reflowParams = ['reflow'];
      if (this.routing.reFresh(this.params, this.previousValue, reflowParams)) {
        if (this.izoomRef) {
          setTimeout(() => {
            this.izoomRef.source.value.reflow();
          }, 500);
        }
        if (this.stockChartRef) {
          setTimeout(() => {
            this.stockChartRef.source.value.reflow();
          }, 500);
        }
      }
      if (
        this.routing.reFresh(this.params, this.previousValue, refreshParams)
      ) {
        this.loading = true;
        this.nodata = false;
        this.initChart();
        this.initZoom();
        if (this.izoomRef) {
          setTimeout(() => {
            this.izoomRef.source.value.reflow();
          }, 1000);
        }
        if (this.stockChartRef) {
          setTimeout(() => {
            this.stockChartRef.source.value.reflow();
          }, 1000);
        }
      }
      this.previousValue = this.params;
    });
  }

  initZoom() {
    const dateFrom = this.yukkApi.myFromTo(
      this.config.appConfig.setup.startDate,
    ).date1;
    const dateTo = moment().add(1, 'days').format('YYYY-MM-DD');
    this.yukkApi
      .scoresTimeSeries('volume', this.params, 'izoom')
      .pipe(
        catchError(() => {
          return of({
            volume_ts: [
              { volume: 0, date: dateFrom },
              { volume: 0, date: dateTo },
            ],
          });
        }),
      )
      .subscribe(
        (result) => {
          const obj = result['volume_ts'];
          const data = obj.map((daily) => {
            const date = this.geTime(daily.date);
            return [date, daily.volume];
          });

          const navigatorEnabled = !this.params.custom_score;

          this.options2 = {
            chart: {
              height: this.fromScanner ? 35 : 60,
              backgroundColor: 'transparent',
            },
            tooltip: { enabled: false },
            title: { enabled: false },
            exporting: { enabled: false },
            rangeSelector: { enabled: false },
            scrollbar: { enabled: false },
            credits: { enabled: false },
            navigator: {
              top: this.fromNewsAudit ? 0 : 12,
              enabled: navigatorEnabled,
              height: !(this.fromNewsAudit || this.fromScanner) ? 30 : 20,
              handles: {
                // enabled: this.params.score ? false : true,
                enabled: this.fromScoreFocus ? false : true,
                backgroundColor: 'rgba(' + this.ioption.color + ', 0.2)',
                borderColor: 'rgba(' + this.ioption.color + ', 0.2)',
                // backgroundColor: !this.params.score ? 'rgba(' + this.ioption.color + ', 0.2)' : 'transparent',
                // borderColor: !this.params.score ? 'rgba(' + this.ioption.color + ', 0.2)' : 'transparent'
              },
              // outlineColor: this.params.score ? this.auth.scoreFocusedColor : 'transparent',
              outlineColor: 'transparent',
              maskFill: 'rgba(' + this.ioption.color + ', 0.1)',
              series: {
                color: 'rgba(' + this.ioption.color + ', 0.2)',
                lineColor: 'transparent',
              },
              xAxis: {
                gridLineColor: 'rgba(' + this.ioption.color + ', 0.2)',
                labels: {
                  style: {
                    color: 'rgba(' + this.ioption.color + ', 0.2)',
                  },
                },
              },
            },
            yAxis: {
              height: 0,
              gridLineWidth: 0,
              labels: {
                enabled: false,
              },
            },
            xAxis: {
              min: this.getRange().min,
              max: this.getRange().max,
              maxRange: this.routing.isDev()
                ? undefined
                : this.auth.internalCockpitSettings.maxTimeframe * 24 * 3600000,
              type: 'datetime',
              ordinal: false,
              lineWidth: 0,
              tickLength: 0,
              labels: {
                enabled: false,
              },
              events: {
                afterSetExtremes: (event) =>
                  this.onAfterSetExtremes(event, true),
              },
            },
            series: [
              {
                type: 'area',
                name: '',
                lineWidth: 0,
                marker: {
                  enabled: false,
                  states: {
                    hover: {
                      enabled: false,
                    },
                  },
                },
                data: data,
                tooltip: {
                  valueDecimals: 2,
                },
              },
            ],
          };
          this.izoom = new StockChart(this.options2);
          this.izoomRef = this.izoom.ref$;
          this.izoomRef.subscribe((res) => {
            if (this.fromScoreFocus) {
              // res.scroller.removeEvents();
            }
            let startDate = '';
            let endDate = '';
            let highchartsTextObject;
            // let highchartsOutlineObject;
            // if (this.params.score && this.auth.scoreFocusedColor) {
            //   highchartsOutlineObject = res.renderer.rect(res.navigator.handles[0].translateX, res.navigator.top, res.navigator.range, res.navigator.height).attr({stroke: this.auth.scoreFocusedColor, fill: 'transparent', 'stroke-width': 1}).add();
            // }
            // const textWidth = 150;
            const calculatePosition = (
              navLeft,
              navRight,
              textSize,
              boxSize,
              boxPadding,
            ) => {
              const positionLeft = Math.round(
                (navLeft + navRight - textSize) * 0.5,
              );
              const positionRight = Math.round(
                (navLeft + navRight + textSize) * 0.5,
              );
              if (positionLeft < 0) {
                return 0;
              } else if (positionRight > boxSize + boxPadding) {
                return boxSize + boxPadding - textSize;
              } else {
                return positionLeft;
              }
            };
            const calculateDates = (
              dateMin,
              dateMax,
              navMin,
              navMax,
              navSize,
            ) => {
              return {
                start: Math.ceil(
                  dateMin + ((dateMax - dateMin) * navMin) / navSize,
                ),
                end: Math.floor(
                  dateMin + ((dateMax - dateMin) * navMax) / navSize,
                ),
              };
            };
            if (navigatorEnabled) {
              const navContainer = document.getElementsByClassName(
                'highcharts-navigator',
              )[0];
              navContainer.addEventListener(
                'mousemove',
                () => {
                  if (
                    highchartsTextObject &&
                    !(Object.keys(highchartsTextObject).length === 0)
                  ) {
                    highchartsTextObject.destroy();
                  }
                  const dateRange = calculateDates(
                    res.navigator.xAxis.min,
                    res.navigator.xAxis.max,
                    res.navigator.zoomedMin,
                    res.navigator.zoomedMax,
                    res.navigator.size,
                  );
                  startDate = moment(dateRange.start).format('DD.MM.YYYY');
                  endDate = moment(dateRange.end).format('DD.MM.YYYY');
                  highchartsTextObject = res.renderer
                    .text(startDate + ' - ' + endDate, 0, 0, 'useHTML')
                    .attr({ id: 'navTooltip' })
                    .css({ color: 'transparent' })
                    .add();
                  const tooltipContainer =
                    document.getElementById('navTooltip');
                  const tooltipContainerWidth = tooltipContainer.offsetWidth;
                  highchartsTextObject.destroy();
                  // const textPositionX = calculatePosition(res.navigator.handles[0].translateX, res.navigator.handles[1].translateX, tooltipContainerWidth, res.navigator.size, res.navigator.left);
                  const textPositionX = calculatePosition(
                    res.navigator.outline.pathArray[1][1],
                    res.navigator.outline.pathArray[3][1],
                    tooltipContainerWidth,
                    res.navigator.size,
                    res.navigator.left,
                  );
                  const color = 'rgba(' + this.ioption.color + ', 0.4)';
                  // const transY = (this.fromNewsAudit || this.fromScanner) ? 4 : 10;
                  // highchartsTextObject = res.renderer.text(startDate + ' - ' + endDate, textPositionX, res.navigator.handles[0].translateY - transY).css({color: color, fontSize: 12}).add();
                  const transY =
                    this.fromNewsAudit || this.fromScanner ? -2.5 : 3.5;
                  highchartsTextObject = res.renderer
                    .text(
                      startDate + ' - ' + endDate,
                      textPositionX,
                      res.navigator.outline.pathArray[1][2] - transY,
                    )
                    .css({ color: color, fontSize: 12 })
                    .add();
                },
                false,
              );
              navContainer.addEventListener(
                'mouseleave',
                () => {
                  if (
                    highchartsTextObject &&
                    !(Object.keys(highchartsTextObject).length === 0)
                  ) {
                    highchartsTextObject.destroy();
                  }
                },
                false,
              );
            }
          });
        },
        () => {
          this.nodata = true;
          this.loading = false;
        },
      );
  }

  initChart() {
    this.annotations = [];
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.params.time
      ? (this.timeframe = this.params.time)
      : (this.timeframe = '7');
    const timeframeLength = this.yukkApi.chartFromTo(this.timeframe).period;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.params.period1
      ? (this.period1 = parseInt(this.params.period1, 10))
      : timeframeLength <= 7
        ? (this.period1 = 1)
        : (this.period1 = 7);
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.params.period2
      ? (this.period2 = parseInt(this.params.period2, 10))
      : timeframeLength <= 7
        ? (this.period2 = 7)
        : (this.period2 = 30);
    const requestScore = this.fromScoreFocus
      ? { requestScore: this.params.score }
      : {};
    forkJoin({
      titolo: this.yukkApi
        .isentiment(
          Object.assign({}, this.params, { getSanctions: true }),
          false,
        )
        .pipe(
          catchError(() => {
            return of({ entity: {}, sentiment: {} });
          }),
        ),
      annotation: this.auth.getNote(this.keyd).pipe(
        catchError(() => {
          return of([]);
        }),
      ),
      volume: this.yukkApi
        .scoresTimeSeries('volume', this.params, 'chart')
        .pipe(
          catchError(() => {
            return of({ volume_ts: [] });
          }),
        ),
      score: !this.fromNewsAudit
        ? this.yukkApi
            .scoresTimeSeries(
              'sentiment',
              Object.assign({}, this.params, {
                period: this.period1,
              }),
              'chart',
            )
            .pipe(
              catchError(() => {
                return of({ quotient_ts: [] });
              }),
            )
        : of({ quotient_ts: [] }),
      score2:
        !this.fromNewsAudit && !this.esgSetup
          ? this.yukkApi
              .scoresTimeSeries(
                'sentiment',
                Object.assign({}, this.params, {
                  period: this.period2,
                }),
                'chart',
              )
              .pipe(
                catchError(() => {
                  return of({ quotient_ts: [] });
                }),
              )
          : of({ quotient_ts: [] }),
      price:
        !this.fromPortfolio && !this.fromSearchQuery && !this.fromNewsAudit
          ? this.yukkApi.scoresTimeSeries('price', this.params, 'chart').pipe(
              catchError(() => {
                return of({ prices: [], currency: [] });
              }),
            )
          : of({ prices: [], currency: [] }),
      scores:
        (!(this.scoreOnChart === 'sentiment') &&
          (this.routing.isFolio() ||
            (this.params.type &&
              (this.params.type === 'company' ||
                this.params.type === 'pne' ||
                ['industry', 'supersector', 'sector', 'subsector'].includes(
                  this.params.type,
                ))))) ||
        (this.routing.isScanner() &&
          (this.isCompanyTag ||
            (this.isPneTag && !['bb'].includes(this.scoreOnChart)))) ||
        Object.prototype.hasOwnProperty.call(this.params, 'isin')
          ? this.params.portfolioId
            ? this.yukkApi
                .scoresTimeSeries(
                  'score',
                  Object.assign({}, requestScore, this.params),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
            : this.yukkApi
                .scoresTimeSeries(
                  'score',
                  Object.assign({}, this.params, requestScore, {
                    scoreForPortfolio: true,
                  }),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
          : of({ score_ts: [] }),
      scoreE:
        this.esgSetup &&
        (this.routing.isFolio() ||
          (this.params.type &&
            (this.params.type === 'company' ||
              this.params.type === 'pne' ||
              ['industry', 'supersector', 'sector', 'subsector'].includes(
                this.params.type,
              ))) ||
          this.params.isin)
          ? this.params.portfolioId
            ? this.yukkApi
                .scoresTimeSeries(
                  'scoreE',
                  Object.assign({}, this.params, {
                    score_type: 'e_standard',
                  }),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
            : this.yukkApi
                .scoresTimeSeries(
                  'scoreE',
                  Object.assign({}, this.params, {
                    scoreForPortfolio: true,
                    score_type: 'e_standard',
                  }),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
          : of({ score_ts: [] }),
      scoreS:
        this.esgSetup &&
        (this.routing.isFolio() ||
          (this.params.type &&
            (this.params.type === 'company' ||
              this.params.type === 'pne' ||
              ['industry', 'supersector', 'sector', 'subsector'].includes(
                this.params.type,
              ))) ||
          this.params.isin)
          ? this.params.portfolioId
            ? this.yukkApi
                .scoresTimeSeries(
                  'scoreS',
                  Object.assign({}, this.params, {
                    score_type: 's_standard',
                  }),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
            : this.yukkApi
                .scoresTimeSeries(
                  'scoreS',
                  Object.assign({}, this.params, {
                    scoreForPortfolio: true,
                    score_type: 's_standard',
                  }),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
          : of({ score_ts: [] }),
      scoreG:
        this.esgSetup &&
        (this.routing.isFolio() ||
          (this.params.type &&
            (this.params.type === 'company' ||
              this.params.type === 'pne' ||
              ['industry', 'supersector', 'sector', 'subsector'].includes(
                this.params.type,
              ))) ||
          this.params.isin)
          ? this.params.portfolioId
            ? this.yukkApi
                .scoresTimeSeries(
                  'scoreG',
                  Object.assign({}, this.params, {
                    score_type: 'g_standard',
                  }),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
            : this.yukkApi
                .scoresTimeSeries(
                  'scoreG',
                  Object.assign({}, this.params, {
                    scoreForPortfolio: true,
                    score_type: 'g_standard',
                  }),
                  'chart',
                )
                .pipe(
                  catchError(() => {
                    return of({ score_ts: [] });
                  }),
                )
          : of({ score_ts: [] }),
      event: !this.fromNewsAudit
        ? this.yukkApi
            .ievent(this.params, this.fromScoreFocus ? this.params.score : null)
            .pipe(
              catchError(() => {
                return of([]);
              }),
            )
        : of([]),
      eventFact1:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { factuality: 'fact' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
      eventFact2:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { factuality: 'probable' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
      eventFact3:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { factuality: 'possible' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
      eventFact4:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { factuality: 'na' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
      eventTemp1:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { temporality: 'past' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
      eventTemp2:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { temporality: 'present' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
      eventTemp3:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { temporality: 'future' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
      eventTemp4:
        !this.fromNewsAudit && this.fromScoreFocus
          ? this.yukkApi
              .ievent(
                Object.assign({}, this.params, { temporality: 'na' }),
                this.fromScoreFocus ? this.params.score : null,
              )
              .pipe(
                catchError(() => {
                  return of([]);
                }),
              )
          : of([]),
    }).subscribe(
      (resArgs) => {
        const results = JSON.parse(JSON.stringify(resArgs));
        if (!this.fromScoreFocus || this.auth.scoreFocusedType === 'score') {
          // quick fix for displaying current Portfolio's name
          if (
            results.titolo.entity.type === 'portfolio' &&
            Object.prototype.hasOwnProperty.call(this.auth, 'folio')
          ) {
            this.titolo = results.titolo;
            this.titolo.entity.name = this.auth.folio
              ? this.auth.folio.name
              : '';
          } else {
            this.titolo = results.titolo;
          }
          if (
            !(this.scoreOnChart === 'sentiment') &&
            (this.routing.isFolio() ||
              (this.params.type &&
                (this.params.type === 'company' ||
                  this.params.type === 'pne' ||
                  ['industry', 'supersector', 'sector', 'subsector'].includes(
                    this.params.type,
                  ))) ||
              (this.routing.isScanner() &&
                (this.isCompanyTag ||
                  (this.isPneTag && !['bb'].includes(this.scoreOnChart)))) ||
              Object.prototype.hasOwnProperty.call(this.params, 'isin'))
          ) {
            if (['bb'].includes(this.scoreOnChart)) {
              this.titolo['score'] =
                results.scores['score_ts'][
                  results.scores['score_ts'].length - 1
                ]['score'] !== null
                  ? results.scores['score_ts'][
                      results.scores['score_ts'].length - 1
                    ]['score']
                  : null;
              this.titolo['trend_value'] =
                results.scores['score_ts'][
                  results.scores['score_ts'].length - 1
                ]['score'] !== null
                  ? results.scores['score_ts'][
                      results.scores['score_ts'].length - 1
                    ]['trend_value']
                  : null;
              this.titolo['trend_type'] =
                results.scores['score_ts'][
                  results.scores['score_ts'].length - 1
                ]['trend_type'];
            } else {
              this.titolo['score'] =
                results.scores['score_ts'][
                  results.scores['score_ts'].length - 1
                ]['score'] !== null
                  ? Number(
                      results.scores['score_ts'][
                        results.scores['score_ts'].length - 1
                      ]['score'].toFixed(1),
                    )
                  : null;
            }
          }
        }
        this.mynotes = results.annotation;
        this.volume_ts = results.volume['volume_ts'];
        this.quotient_ts = results.score['quotient_ts'];
        this.quotient_ts2 = results.score2['quotient_ts'];
        this.score_ts = !this.fromNewsAudit ? results.scores['score_ts'] : [];
        this.score_ts_E = !this.fromNewsAudit ? results.scoreE['score_ts'] : [];
        this.score_ts_S = !this.fromNewsAudit ? results.scoreS['score_ts'] : [];
        this.score_ts_G = !this.fromNewsAudit ? results.scoreG['score_ts'] : [];
        this.scoreE_last = results.scoreE['score_ts_last_score']
          ? results.scoreE['score_ts_last_score']['score']
          : null;
        this.scoreS_last = results.scoreS['score_ts_last_score']
          ? results.scoreS['score_ts_last_score']['score']
          : null;
        this.scoreG_last = results.scoreG['score_ts_last_score']
          ? results.scoreG['score_ts_last_score']['score']
          : null;
        this.prices = results.price['prices'];
        this.currency = results.price['currency'];
        this.serie_multi = [];
        this.events = results.event;
        this.eventsAnalytics = {
          eventsTemp1: results.eventTemp1,
          eventsTemp2: results.eventTemp2,
          eventsTemp3: results.eventTemp3,
          eventsTemp4: results.eventTemp4,
          eventsFact1: results.eventFact1,
          eventsFact2: results.eventFact2,
          eventsFact3: results.eventFact3,
          eventsFact4: results.eventFact4,
        };
        if (!this.routing.isMobile()) {
          this.getNote();
        } else {
          if (this.score_ts && this.score_ts.length) {
            this.getScoreArea();
          } else {
            this.getArea();
          }
        }

        // redraw chart after loading all responses
        if (this.izoomRef) {
          setTimeout(() => {
            this.izoomRef.source.value.reflow();
          }, 1000);
        }
        if (this.stockChartRef) {
          setTimeout(() => {
            this.stockChartRef.source.value.reflow();
          }, 1000);
        }
      },
      () => {
        this.nodata = true;
        this.loading = false;
      },
    );
  }

  iSentiment(value) {
    return (value * 100).toFixed() + '%';
  }
  iColor(value) {
    return 'style="background-color:' + this.colorpipe.transform(value) + '"';
  }

  iSentimentScore(value) {
    return value?.toFixed(2);
  }
  iColorScore(value) {
    if (this.fromScoreFocus) {
      if (this.auth.getScoreType(this.params.score) === 'pos') {
        return (
          'style="background-color:' + this.colorpipeScore2.transform(value)
        );
      } else {
        return (
          'style="background-color:' + this.colorpipeScore.transform(value)
        );
      }
    } else {
      if (this.auth.getScoreType() === 'pos') {
        return (
          'style="background-color:' + this.colorpipeScore2.transform(value)
        );
      } else {
        return (
          'style="background-color:' + this.colorpipeScore.transform(value)
        );
      }
    }
  }

  getNote() {
    if (this.mynotes.length) {
      const uniques = new Set();
      const annotations = [];
      this.mynotes.forEach((note) => {
        if (!uniques.has(note.position)) {
          uniques.add(note.position);
          annotations.push(note);
        }
      });
      annotations.forEach((note) => {
        const position = moment(note.position).valueOf();
        if (
          position >= this.getRange().min &&
          position <= this.getRange().max
        ) {
          let ydata;
          let senti;
          if (this.params.annotation) {
            const volume = this.volume_ts.filter((item) => {
              return moment(item.date).format('YYYY-MM-DD') === note.position;
            });
            if (volume[0]) {
              ydata = volume[0].volume;
            }
          } else {
            if (this.score_ts && this.score_ts.length) {
              const score2 = this.score_ts.filter((item) => {
                return moment(item.date).format('YYYY-MM-DD') === note.position;
              });
              if (score2[0]) {
                ydata = score2[0].score;
              }
            } else {
              const score2 = this.quotient_ts.filter((item) => {
                return moment(item.date).format('YYYY-MM-DD') === note.position;
              });
              if (score2[0]) {
                ydata = score2[0].quotient;
              }
            }
          }
          let score;
          if (this.score_ts && this.score_ts.length) {
            score = this.score_ts.filter((item) => {
              return moment(item.date).format('YYYY-MM-DD') === note.position;
            });
          } else {
            score = this.quotient_ts.filter((item) => {
              return moment(item.date).format('YYYY-MM-DD') === note.position;
            });
          }
          if (score[0]) {
            senti =
              this.score_ts && this.score_ts.length
                ? score[0].score
                : score[0].quotient;
            let icon = '';
            if (note.is_global) {
              icon =
                '<svg xmlns="http://www.w3.org/2000/svg" class="iconsvg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path fill="grey" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 17.93c-3.95-.49-7-3.85-7-7.93 0-.62.08-1.21.21-1.79L9 15v1c0 1.1.9 2 2 2v1.93zm6.9-2.54c-.26-.81-1-1.39-1.9-1.39h-1v-3c0-.55-.45-1-1-1H8v-2h2c.55 0 1-.45 1-1V7h2c1.1 0 2-.9 2-2v-.41c2.93 1.19 5 4.06 5 7.41 0 2.08-.8 3.97-2.1 5.39z"/></svg>';
            }
            let text;
            if (this.score_ts && this.score_ts.length) {
              text =
                '<span class="labelstyle">' +
                icon +
                note.annotation +
                ' <span class="percent" ' +
                this.iColorScore(senti) +
                '>' +
                this.iSentimentScore(senti) +
                '</span>' +
                '</span>';
            } else {
              text =
                '<span class="labelstyle">' +
                icon +
                note.annotation +
                ' <span class="percent" ' +
                this.iColor(senti) +
                '>' +
                this.iSentiment(senti) +
                '</span>' +
                '</span>';
            }
            const data = {
              shape: 'callout',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              text: text,
              useHTML: true,
              point: {
                xAxis: 0,
                yAxis: this.params.annotation
                  ? 0
                  : this.score_ts && this.score_ts.length
                    ? 2
                    : 1,
                x: this.geTime(note.position),
                y: ydata,
              },
            };
            this.annotations.push(data);
          }
        }
      });
    }
    if (this.score_ts && this.score_ts.length) {
      this.getScoreArea();
    } else {
      this.getArea();
    }
  }

  getArea() {
    const data = this.quotient_ts.map((daily) => {
      return [this.geTime(daily.date), daily.quotient];
    });
    const serie_rol = {
      type: 'area',
      name: 'Area',
      lineWidth: 0,
      // threshold: 0,
      color: this.colorpipe.transform(this.titolo.sentiment.sentiment),
      yAxis: 1,
      data: data,
      visible: true,
      showInLegend: false,
      linkedTo: 'average-1',
      tooltip: {
        enabled: false,
      },
      enableMouseTracking: false,
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, this.colorpipe.transform(this.titolo.sentiment.sentiment)],
          [1, 'rgba(0, 0, 0, 0)'],
        ],
      },
    };
    this.serie_multi.push(serie_rol);
    this.getVolume();
  }

  getScoreArea() {
    const data_score = this.score_ts.map((daily) => {
      if (['bb'].includes(this.scoreOnChart)) {
        return [
          this.geTime(moment(daily.date).format('YYYY-MM-DD')),
          daily.score !== null ? Number(daily.score.toFixed(2)) : null,
        ];
      } else {
        return [
          this.geTime(moment(daily.date).format('YYYY-MM-DD')),
          daily.score !== null ? Number(daily.score.toFixed(2)) : null,
        ];
      }
    });
    let color = 'transparent';
    const lastScore =
      this.score_ts[this.score_ts.length - 1]['score'] !== null
        ? this.score_ts[this.score_ts.length - 1]['score']
        : null;
    if (this.params.custom_score) {
      if (this.params.custom_score === 'esg') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (this.params.custom_score === 'eb_sim') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (this.params.custom_score === 'eb_sim_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'general_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'credit_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (['bb'].includes(this.params.custom_score)) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'esg_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'immediate_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.auth.getScoreType(this.params.custom_score) === 'pos') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else {
        color = this.colorpipeScore.transform(this.titolo.score);
      }
    } else if (this.fromScoreFocus) {
      if (this.params.score && this.params.score === 'esg') {
        color = this.colorpipeScore2.transform(lastScore);
      } else if (this.params.score && this.params.score === 'eb_sim') {
        color = this.colorpipeScore2.transform(lastScore);
      } else if (this.params.score && this.params.score === 'eb_sim_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'general_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'credit_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && ['bb'].includes(this.params.score)) {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'esg_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'immediate_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.auth.getScoreType(this.scoreOnChart) === 'pos') {
        color = this.colorpipeScore2.transform(lastScore);
      } else {
        color = this.colorpipeScore.transform(lastScore);
      }
    } else {
      if (this.params.score_type && this.params.score_type === 'esg') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'eb_sim'
      ) {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'eb_sim_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'general_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'credit_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        ['bb'].includes(this.params.score_type)
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'esg_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'immediate_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.auth.getScoreType() === 'pos') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else {
        color = this.colorpipeScore.transform(this.titolo.score);
      }
    }
    if (['bb'].includes(this.scoreOnChart)) {
      color =
        this.theme === 'dark'
          ? 'rgba(255, 255, 255, 0.5)'
          : 'rgba(0, 0, 0, 0.5)';
    }
    const serie_rol = {
      type: 'area',
      name: 'ScoreArea',
      lineWidth: 0,
      // threshold: 0,
      color: color,
      yAxis: 2,
      data: data_score,
      visible: true,
      showInLegend: false,
      linkedTo: 'score',
      tooltip: {
        enabled: false,
      },
      enableMouseTracking: false,
      fillColor: {
        linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
        stops: [
          [0, color],
          [1, 'rgba(0, 0, 0, 0)'],
        ],
      },
    };
    this.serie_multi.push(serie_rol);
    this.getVolume();
  }

  getVolume() {
    const data = this.volume_ts.map((daily) => {
      return [this.geTime(daily.date), daily.volume];
    });
    const serie_vol = {
      type: 'column',
      name: 'Volume',
      color: 'rgba(' + this.ioption.colorAudit + ', 1.0)',
      borderWidth: 0,
      yAxis: 0,
      data: data,
      // pointPadding: 0.5,
      maxPointWidth: this.fromScanner ? 7 : undefined,
      // minPointLength: (this.fromScanner) ? 3 : 0,
      // pointWidth: (this.fromScanner) ? 7 : undefined,
      // dataGrouping: {
      //   enabled: true,
      //   units: (this.fromScanner) ? [['week', [1]], ['day', [1]]] : undefined
      // },
      showInLegend: !this.fromNewsAudit && !this.fromScanner,
      visible: !this.params.eventid && this.showVolumeInLegend,
      tooltip: {
        pointFormat:
          '<tr><td style="color: {series.color}">{series.name}: </td><td style="text-align:right"><b>{point.y}</b></td></tr>',
      },
      events: {
        click: (event) => {
          if (this.params.custom_score) {
            return;
          }
          let noteid = null;
          let newsday = null;
          let time = this.params.time;
          if (event.point.dataGroup) {
            if (event.point.dataGroup.length === 7) {
              const week = moment(this.getRange().min).add(
                event.point.dataGroup.start,
                'days',
              );
              // newsday = week.format('YYYY-MM-DD')
              time =
                week.format('YYYY-MM-DD') +
                '--' +
                week.add(7, 'days').format('YYYY-MM-DD');
            }
            if (event.point.dataGroup.length === 1) {
              newsday = moment(event.point.x).format('YYYY-MM-DD');
            }
          } else {
            newsday = moment(event.point.x).format('YYYY-MM-DD');
          }
          if (this.mynotes.length) {
            const idnote = this.mynotes.filter((risp) => {
              if (
                moment(event.point.x).valueOf() ===
                moment(risp.position).valueOf()
              ) {
                return risp;
              }
            });
            if (idnote[0]) {
              noteid = idnote[0].uid;
            }
          }
          let path = [];
          if (this.routing.isMobile()) {
            path = ['/mobile/news/newsfeed'];
          }
          this.router.navigate(path, {
            queryParams: {
              newsday: newsday,
              note: noteid,
              news: null,
              tag: null,
              time: time,
            },
            queryParamsHandling: 'merge',
            replaceUrl: false,
          });
        },
        legendItemClick: (event) => {
          this.showVolumeInLegend = !event.target.visible;
        },
      },
    };
    this.serie_multi.push(serie_vol);
    this.getEvent();
  }

  getEvent() {
    if (this.events.length > 0) {
      const name = 'Events';
      let data = [];
      if (this.params.eventype === 'sub_event') {
        const dataevent = this.events.filter(
          (d) => d.sub_event.id === this.params.eventid,
        );
        data = _.map(
          _.toPairs(
            _.mapValues(
              _.groupBy(dataevent, (d) => d.date),
              (vs) => _.sumBy(vs, (v) => v.count),
            ),
          ),
          (x) => ({ date: x[0], count: x[1] }),
        );
      } else {
        data = _.map(
          _.toPairs(
            _.mapValues(
              _.groupBy(this.events, (d) => d.date),
              (vs) => _.sumBy(vs, (v) => v.count),
            ),
          ),
          (x) => ({ date: x[0], count: x[1] }),
        );
      }
      const serie_event = {
        type: 'column',
        name: name,
        // color: '#008B8B',
        color: 'rgba(0, 139, 139, 1.0)',
        borderWidth: 0,
        yAxis: 0,
        data: data.map((item) => [this.geTime(item.date), item.count]),
        showInLegend: !this.fromNewsAudit && !this.fromScanner,
        // visible: (!this.fromScanner && this.showEventsInLegend),
        visible: this.showEventsInLegend,
        tooltip: {
          pointFormat:
            '<tr><td style="color: {series.color}">{series.name}: </td><td style="text-align:right"><b>{point.y}</b></td></tr>',
        },
        events: {
          click: (event) => {
            if (this.params.custom_score) {
              return;
            }
            const noteid = null;
            let newsday = null;
            let time = this.params.time;
            if (event.point.dataGroup) {
              if (event.point.dataGroup.length === 7) {
                const week = moment(this.getRange().min).add(
                  event.point.dataGroup.start,
                  'days',
                );
                // newsday = week.format('YYYY-MM-DD')
                time =
                  week.format('YYYY-MM-DD') +
                  '--' +
                  week.add(7, 'days').format('YYYY-MM-DD');
              }
              if (event.point.dataGroup.length === 1) {
                newsday = moment(event.point.x).format('YYYY-MM-DD');
              }
            } else {
              newsday = moment(event.point.x).format('YYYY-MM-DD');
            }
            let path = [];
            if (this.routing.isMobile()) {
              path = ['/mobile/news/newsfeed'];
            }
            this.router.navigate(path, {
              queryParams: {
                newsday: newsday,
                note: noteid,
                news: null,
                tag: null,
                time: time,
              },
              queryParamsHandling: 'merge',
              replaceUrl: false,
            });
          },
          legendItemClick: (event) => {
            this.showEventsInLegend = !event.target.visible;
          },
        },
      };
      this.auth.scoreFocusedEvents = JSON.parse(JSON.stringify(this.events));
      const eventsAnalyticsRawData = JSON.parse(
        JSON.stringify(this.eventsAnalytics),
      );
      this.auth.scoreFocusedEventsAnalytics = {
        fact1: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsFact1, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
        fact2: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsFact2, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
        fact3: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsFact3, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
        fact4: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsFact4, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
        temp1: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsTemp1, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
        temp2: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsTemp2, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
        temp3: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsTemp3, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
        temp4: _.mapValues(
          _.groupBy(eventsAnalyticsRawData.eventsTemp4, (e) => e.sub_event.id),
          (vs) => _.sumBy(vs, (v) => v.count),
        ),
      };
      this.serie_multi.push(serie_event);
    }
    this.getScore();
  }

  getScore() {
    const data = this.quotient_ts.map((daily) => {
      return [this.geTime(daily.date), daily.quotient];
    });
    const serie_rol = {
      id: 'average-1',
      type: 'line',
      name: this.period1 + (this.period1 > 1 ? ' days' : ' day') + ' Sentiment',
      lineWidth: !(this.score_ts && this.score_ts.length) ? 4 : 3,
      color: this.colorpipe.transform(this.titolo.sentiment.sentiment),
      yAxis: 1,
      data: data,
      visible:
        !(this.score_ts && this.score_ts.length) || this.showSentiment1InLegend,
      showInLegend: !this.fromNewsAudit && !this.fromScanner,
      tooltip: {
        valueDecimals: 2,
        pointFormatter: function () {
          const percent = this.y * 100;
          const string =
            '<tr><td style="color:' +
            this.series.color +
            '">' +
            this.series.name +
            ' : </td>' +
            '<td style="text-align:right"><b>' +
            percent.toFixed(0) +
            '%</b></td></tr>';
          return string;
        },
      },
      marker: {
        symbol: 'circle',
      },
      events: {
        legendItemClick: (event) => {
          this.showSentiment1InLegend = !event.target.visible;
        },
      },
    };
    this.serie_multi.push(serie_rol);
    this.getScore2();
  }

  getScore2() {
    const data = this.quotient_ts2.map((daily) => {
      return [this.geTime(daily.date), daily.quotient];
    });
    const serie_rol = {
      type: 'line',
      name: this.period2 + (this.period2 > 1 ? ' days' : ' day') + ' Sentiment',
      lineWidth: 3,
      dashStyle: 'ShortDot',
      color: 'rgba(163, 81, 254, 0.6)',
      yAxis: 1,
      data: data,
      visible:
        !(this.score_ts && this.score_ts.length) ||
        this.showSentiment2InLegend /* && !(this.fromScanner)*/,
      showInLegend: !this.fromNewsAudit && !this.fromScanner && !this.esgSetup,
      tooltip: {
        valueDecimals: 2,
        pointFormatter: function () {
          const percent = this.y * 100;
          const string =
            '<tr><td style="color:' +
            this.series.color +
            '">' +
            this.series.name +
            ' : </td>' +
            '<td style="text-align:right"><b>' +
            percent.toFixed(0) +
            '%</b></td></tr>';
          return string;
        },
      },
      marker: {
        symbol: 'circle',
      },
      events: {
        legendItemClick: (event) => {
          this.showSentiment2InLegend = !event.target.visible;
        },
      },
    };
    this.serie_multi.push(serie_rol);
    if (this.score_ts && this.score_ts.length) {
      this.getScores();
    } else {
      this.getPrice();
    }
  }

  getScores() {
    const data_score = this.score_ts.map((daily) => {
      if (['bb'].includes(this.scoreOnChart)) {
        return [
          this.geTime(moment(daily.date).format('YYYY-MM-DD')),
          daily.score !== null ? Number(daily.score.toFixed(2)) : null,
        ];
      } else {
        return [
          this.geTime(moment(daily.date).format('YYYY-MM-DD')),
          daily.score !== null ? Number(daily.score.toFixed(2)) : null,
        ];
      }
    });

    let color = 'transparent';
    const lastScore =
      this.score_ts[this.score_ts.length - 1]['score'] !== null
        ? this.score_ts[this.score_ts.length - 1]['score']
        : null;
    if (this.params.custom_score) {
      if (this.params.custom_score === 'esg') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (this.params.custom_score === 'eb_sim') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (this.params.custom_score === 'eb_sim_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'general_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'credit_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (['bb'].includes(this.params.custom_score)) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'esg_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.params.custom_score === 'immediate_risk') {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.auth.getScoreType(this.params.custom_score) === 'pos') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else {
        color = this.colorpipeScore.transform(this.titolo.score);
      }
    } else if (this.fromScoreFocus) {
      if (this.params.score && this.params.score === 'esg') {
        color = this.colorpipeScore2.transform(lastScore);
      } else if (this.params.score && this.params.score === 'eb_sim') {
        color = this.colorpipeScore2.transform(lastScore);
      } else if (this.params.score && this.params.score === 'eb_sim_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'general_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'credit_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && ['bb'].includes(this.params.score)) {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'esg_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.params.score && this.params.score === 'immediate_risk') {
        color = this.colorpipeScore.transform(lastScore);
      } else if (this.auth.getScoreType(this.scoreOnChart) === 'pos') {
        color = this.colorpipeScore2.transform(lastScore);
      } else {
        color = this.colorpipeScore.transform(lastScore);
      }
    } else {
      if (this.params.score_type && this.params.score_type === 'esg') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'eb_sim'
      ) {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'eb_sim_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'general_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'credit_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        ['bb'].includes(this.params.score_type)
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'esg_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (
        this.params.score_type &&
        this.params.score_type === 'immediate_risk'
      ) {
        color = this.colorpipeScore.transform(this.titolo.score);
      } else if (this.auth.getScoreType() === 'pos') {
        color = this.colorpipeScore2.transform(this.titolo.score);
      } else {
        color = this.colorpipeScore.transform(this.titolo.score);
      }
    }
    if (['bb'].includes(this.scoreOnChart)) {
      color =
        this.theme === 'dark'
          ? 'rgba(255, 255, 255, 0.5)'
          : 'rgba(0, 0, 0, 0.5)';
    }

    if (this.fromScoreFocus) {
      data_score[data_score.length - 1] = {
        x: data_score[data_score.length - 1][0],
        y: data_score[data_score.length - 1][1],
        marker: {
          enabled: true,
          symbol: 'circle',
          radius: 4,
        },
        dataLabels: {
          enabled: true,
          style: {
            color: color,
            textOutline: this.theme === 'dark' ? '#434343' : '#e4e4e4',
          },
        },
      };
    }

    const serie_score = {
      id: 'score',
      type: 'line',
      name:
        this.fromWidget && this.params.score_type
          ? this.params.score_type === 'esg'
            ? 'ESG Score'
            : 'Risk Score'
          : this.auth.getScoresInfo(this.scoreOnChart)['short'] + ' Score',
      lineWidth: 4,
      color: color,
      yAxis: 2,
      data: data_score,
      visible: this.showScoreInLegend,
      showInLegend: !this.fromNewsAudit && !this.fromScanner,
      tooltip: {
        pointFormat:
          '<tr><td style="color:{series.color}">{series.name}: </td>' +
          '<td style="text-align:right"><b>{point.y}' +
          (['bb'].includes(this.scoreOnChart) ? '' : '') +
          '</b></td></tr>',
      },
      marker: {
        symbol: 'circle',
      },
      events: {
        legendItemClick: (event) => {
          this.showScoreInLegend = !event.target.visible;
        },
      },
    };
    this.serie_multi.push(serie_score);
    if (this.esgSetup) {
      if (this.score_ts_E && this.score_ts_E.length) {
        this.getScoreE();
      } else {
        this.getScoreS();
      }
    } else {
      this.getPrice();
    }
  }

  getScoreE() {
    const data_score = this.score_ts_E.map((daily) => {
      return [
        this.geTime(moment(daily.date).format('YYYY-MM-DD')),
        daily.score !== null ? Number(daily.score.toFixed(2)) : null,
      ];
    });
    const color = this.colorpipeScore2.transform(this.scoreE_last);
    const serie_score = {
      id: 'scoreE',
      type: 'line',
      name: 'E Score',
      lineWidth: 2,
      dashStyle: 'ShortDash',
      color: color,
      yAxis: 2,
      data: data_score,
      visible: this.showScoreEInLegend,
      showInLegend: !this.fromNewsAudit && !this.fromScanner,
      tooltip: {
        pointFormat:
          '<tr><td style="color:{series.color}">{series.name}: </td>' +
          '<td style="text-align:right"><b>{point.y}</b></td></tr>',
      },
      marker: {
        symbol: 'circle',
      },
      events: {
        legendItemClick: (event) => {
          this.showScoreEInLegend = !event.target.visible;
        },
      },
    };
    this.serie_multi.push(serie_score);
    if (this.score_ts_S && this.score_ts_S.length) {
      this.getScoreS();
    } else {
      this.getScoreG();
    }
  }

  getScoreS() {
    const data_score = this.score_ts_S.map((daily) => {
      return [
        this.geTime(moment(daily.date).format('YYYY-MM-DD')),
        daily.score !== null ? Number(daily.score.toFixed(2)) : null,
      ];
    });
    const color = this.colorpipeScore2.transform(this.scoreS_last);
    const serie_score = {
      id: 'scoreS',
      type: 'line',
      name: 'S Score',
      lineWidth: 2,
      dashStyle: 'ShortDashDot',
      color: color,
      yAxis: 2,
      data: data_score,
      visible: this.showScoreSInLegend,
      showInLegend: !this.fromNewsAudit && !this.fromScanner,
      tooltip: {
        pointFormat:
          '<tr><td style="color:{series.color}">{series.name}: </td>' +
          '<td style="text-align:right"><b>{point.y}</b></td></tr>',
      },
      marker: {
        symbol: 'circle',
      },
      events: {
        legendItemClick: (event) => {
          this.showScoreSInLegend = !event.target.visible;
        },
      },
    };
    this.serie_multi.push(serie_score);
    if (this.score_ts_G && this.score_ts_G.length) {
      this.getScoreG();
    } else {
      this.getPrice();
    }
  }

  getScoreG() {
    const data_score = this.score_ts_G.map((daily) => {
      return [
        this.geTime(moment(daily.date).format('YYYY-MM-DD')),
        daily.score !== null ? Number(daily.score.toFixed(2)) : null,
      ];
    });
    const color = this.colorpipeScore2.transform(this.scoreG_last);
    const serie_score = {
      id: 'scoreG',
      type: 'line',
      name: 'G Score',
      lineWidth: 2,
      dashStyle: 'ShortDot',
      color: color,
      yAxis: 2,
      data: data_score,
      visible: this.showScoreGInLegend,
      showInLegend: !this.fromNewsAudit && !this.fromScanner,
      tooltip: {
        pointFormat:
          '<tr><td style="color:{series.color}">{series.name}: </td>' +
          '<td style="text-align:right"><b>{point.y}</b></td></tr>',
      },
      marker: {
        symbol: 'circle',
      },
      events: {
        legendItemClick: (event) => {
          this.showScoreGInLegend = !event.target.visible;
        },
      },
    };
    this.serie_multi.push(serie_score);
    this.getPrice();
  }

  getPrice() {
    if (this.prices && this.prices.length) {
      const data_price = this.prices.map((daily) => {
        return [
          this.geTime(moment(daily.date).format('YYYY-MM-DD')),
          daily.price,
        ];
      });
      this.ioption.isprice = true;
      const serie_price = {
        type: 'line',
        name: 'Price ' + this.currency,
        lineWidth: 2,
        color: 'rgba(' + this.ioption.color + ', 0.3)',
        yAxis: 3,
        data: data_price,
        visible:
          !(this.score_ts && this.score_ts.length) || this.showPriceInLegend,
        showInLegend: !this.fromNewsAudit && !this.fromScanner,
        tooltip: {
          pointFormat:
            '<tr><td style="color:{series.color}">{series.name}: </td>' +
            '<td style="text-align:right"><b>{point.y}</b></td></tr>',
        },
        marker: {
          symbol: 'circle',
        },
        events: {
          legendItemClick: (event) => {
            this.showPriceInLegend = !event.target.visible;
          },
        },
      };
      this.serie_multi.push(serie_price);
      this.getOption();
    } else {
      this.getOption();
    }
  }

  getOption() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    const thatScore = this.scoreOnChart;
    let marginBottom = null;
    if (this.fromScanner) {
      marginBottom = 30;
    } else {
      marginBottom = 25;
    }
    this.options = {
      title: { enabled: false },
      chart: {
        marginTop: null,
        marginBottom: marginBottom,
        backgroundColor: 'transparent',
        zoomType: 'x',
        alignTicks: false,
        events: {
          load: function () {},
          render: function () {
            if (this.resetZoomButton && !that.fromScoreFocus) {
              this.resetZoomButton.hide();
            }
            // this.resetZoomButton.destroy();
          },
        },
      },
      scrollbar: { enabled: false },
      credits: { enabled: false },
      exporting: {
        enabled: false,
      },
      legend: {
        enabled: this.routing.isMobile() ? false : true,
        // layout: 'vertical',
        // floating: true,
        // backgroundColor: 'rgba(0, 0, 0, 0.5)',
        verticalAlign: 'top',
        align: 'left',
        y: 0,
        // symbolWidth: 33,
        itemStyle: {
          color: 'rgba(' + this.ioption.color + ', 0.4)',
        },
        itemHoverStyle: {
          color: 'rgba(' + this.ioption.color + ', 0.6)',
        },
        itemHiddenStyle: {
          color: 'rgba(' + this.ioption.color + ', 0.1)',
        },
      },
      tooltip: {
        // formatter: function () {
        //   return this.points.reduce(function (s, point) {
        //     if (point.series.name == 'Volume') {
        //       return s + '<br/>' + point.series.name + ': ' +
        //         point.y + ''
        //     }
        //     if (point.series.name == 'Sentiment') {
        //       return s + '<br/>' + point.series.name + ': ' +
        //         point.y * 100 + '%'
        //     }
        //   }, '<b>' + this.x + '</b>')
        // },

        shared: true,
        split: false,
        followPointer: true,
        followTouchMove: true,
        backgroundColor: 'rgba(' + this.ioption.color + ', 0.1)',
        borderColor: 'transparent',
        shadow: false,
        style: {
          color: 'white',
          fontSize: 14,
        },
        useHTML: true,
        headerFormat: '<small>{point.key}</small><table>',
        // pointFormat: '<tr><td style="color: {series.color}">{series.name}: </td>' +
        //   '<td style="text-align:right"><b>{point.y:.2f}</b></td></tr>',
        footerFormat: '</table>',
      },
      rangeSelector: { enabled: false },
      navigator: {
        enabled: false,
        height: 20,
        adaptToUpdatedData: false,
        baseSeries: 1,
        handles: {
          backgroundColor: 'rgba(' + this.ioption.color + ', 0.2)',
          borderColor: 'rgba(' + this.ioption.color + ', 0.2)',
        },
        outlineColor: 'transparent',
        maskFill: 'rgba(' + this.ioption.color + ', 0.1)',
        series: {
          color: 'rgba(' + this.ioption.color + ', 0.2)',
          lineColor: 'transparent',
        },
        xAxis: {
          gridLineColor: 'rgba(' + this.ioption.color + ', 0.2)',
          labels: {
            style: {
              color: 'rgba(' + this.ioption.color + ', 0.2)',
            },
          },
        },
      },
      xAxis: {
        type: 'datetime',
        ordinal: false,
        // startOnTick: (this.fromScanner) ? true : false,
        // endOnTick: (this.fromScanner) ? true : false,
        // tickPixelInterval: (this.fromScanner) ? 50 : 100,
        // min: this.getRange().min,
        // max: this.getRange().max,
        labels: {
          style: {
            color: 'rgba(' + this.ioption.color + ', 0.4)',
          },
          rotation: 0,
        },
        lineColor: 'rgba(' + this.ioption.color + ', 0.2)',
        tickColor: 'rgba(' + this.ioption.color + ', 0.2)',
        crosshair: {
          enabled: false,
          snap: false,
          width: 1,
          color: 'rgba(' + this.ioption.color + ', 0.2)',
        },
        gridLineColor: 'rgba(' + this.ioption.color + ', 0.05)',
        gridLineWidth: 0,
        events: {
          afterSetExtremes: (event) => this.onAfterSetExtremes(event, false),
        },
      },
      yAxis: [
        {
          title: { text: 'Volume', enabled: false },
          endOnTick: !this.fromNewsAudit,
          opposite: false,
          allowDecimals: false,
          labels: {
            y: 3,
            style: { color: 'rgba(' + this.ioption.color + ', 0.3)' },
            enabled: !this.fromNewsAudit,
          },
          gridLineColor: 'transparent',
          lineColor: 'rgba(' + this.ioption.color + ', 0.05)',
          lineWidth: 2,
        },
        {
          title: {
            text:
              this.auth.getScoresInfo(this.scoreOnChart)['short'] + ' Score',
            enabled: false,
          },
          max: this.fully.max,
          min: this.fully.min,
          // startOnTick: true,
          // endOnTick: true,
          labels: {
            // y: 3,
            align: 'left',
            style: {
              color: 'rgba(' + this.ioption.color + ', 0.5)',
              fontWeight: 'normal',
            },
            enabled: !(this.score_ts && this.score_ts.length),
            formatter: function () {
              return Math.round(this.value * 100) + '%';
            },
          },
          gridLineColor: !(this.score_ts && this.score_ts.length)
            ? 'rgba(' + this.ioption.color + ', 0.05)'
            : 'transparent',
          gridLineWidth: this.ioption.yAxis.score.gridLineWidth,
          lineColor: !(this.score_ts && this.score_ts.length)
            ? 'rgba(' + this.ioption.color + ', 0.05)'
            : 'transparent',
          lineWidth: 2,
        },
        {
          title: {
            text:
              this.auth.getScoresInfo(this.scoreOnChart)['short'] + ' Score',
            enabled: false,
          },
          opposite: true,
          max: null,
          min: null,
          labels: {
            // y: 3,
            align: 'left',
            style: {
              color: 'rgba(' + this.ioption.color + ', 0.5)',
              fontWeight: 'normal',
            },
            // enabled: this.ioption.isprice,
            enabled: this.score_ts && this.score_ts.length,
            formatter: function () {
              if (['bb'].includes(thatScore)) {
                return this.value;
              } else {
                return this.value;
              }
            },
          },
          gridLineColor:
            this.score_ts && this.score_ts.length
              ? 'rgba(' + this.ioption.color + ', 0.05)'
              : 'transparent',
          gridLineWidth: this.ioption.yAxis.score.gridLineWidth,
          lineColor:
            this.score_ts && this.score_ts.length
              ? 'rgba(' + this.ioption.color + ', 0.05)'
              : 'transparent',
          lineWidth: 2,
        },
        {
          title: { text: 'Price', enabled: false },
          opposite: false,
          labels: {
            y: 3,
            style: { color: 'rgba(' + this.ioption.color + ', .4)' },
            // enabled: this.ioption.isprice,
            enabled: false,
          },
          gridLineColor: 'transparent',
        },
      ],
      plotOptions: {
        series: {
          states: {
            inactive: {
              opacity: 1,
            },
          },
          maxPointWidth: 20,
          // dataGrouping: {
          //   // forced: false,
          //   units: [
          //     ['day', [3]]
          //   ]
          // },
          marker: {
            enabled: this.yukkApi.chartFromTo(this.timeframe).period < 31,
          },
          cursor: 'pointer',
          connectNulls: true,
          // clip: true
          // crisp: false
        },
        column: {
          grouping: true,
          states: {
            hover: {
              color: 'rgba(' + this.ioption.color + ', 0.4)',
            },
          },
        },
      },
      annotations: [
        {
          shapeOptions: {
            stroke: '#2f4f4f',
            strokeWidth: 3,
          },
          labels: this.annotations,
        },
        {
          labels: this.ievents,
        },
      ],
      series: this.serie_multi,
    };
    this.stockChart = new StockChart(this.options);
    this.stockChartRef = this.stockChart.ref$;

    this.loading = false;
  }

  getStops(data) {
    const data1 = data
      .filter(
        (risp) =>
          risp[1] != null &&
          new Date(risp[0]).getTime() > this.getRange().min &&
          new Date(risp[0]).getTime() < this.getRange().max,
      )
      .sort((a, b) => a - b)
      .map((risp) => risp[1]);
    // eslint-disable-next-line prefer-spread
    const value_min = Math.min.apply(Math, data1);
    // eslint-disable-next-line prefer-spread
    const value_max = Math.max.apply(Math, data1);
    const value_mid = (value_min + value_max) / 2;
    const colorScale = d3
      .scaleLinear()
      .domain([0, 0.5, 1])
      .range(['#dd001a', '#f6d500', '#3dac00']);
    return [
      [0, colorScale(value_max.toFixed(2))],
      [0.5, colorScale(value_mid.toFixed(2))],
      [1, colorScale(value_min.toFixed(2))],
    ];
  }

  getRange() {
    const fromto = this.yukkApi.chartFromTo(this.timeframe);
    const range = {
      min: moment.utc(fromto.date1).valueOf(),
      max: moment.utc(fromto.date2).valueOf(),
    };
    return range;
  }

  getAllDaysFormatted() {
    const fromto = this.yukkApi.chartFromTo(this.timeframe);
    const range = {
      min: moment.utc(fromto.date1),
      max: moment.utc(fromto.date2),
    };
    const howManyDays = range.max.diff(range.min, 'days');
    const allDays = [];
    for (let i = 0; i <= howManyDays; i++) {
      allDays.push(
        moment(range.min).add(i, 'days').format('YYYY-MM-DD').valueOf(),
      );
    }
    return allDays;
  }

  inChart() {
    if (this.routing.isPath('iframe')) {
      const bool = this.params.chartvol === 'false';
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { chartvol: bool },
        queryParamsHandling: 'merge',
      });
    }
  }

  goInfo() {
    this.dialog.open(ChartLegendComponent, { data: {} });
  }

  getAverage() {
    if (this.showAverage2) {
      this.showAverage2 = false;
    }
    this.showAverage = !this.showAverage;
  }

  getAverage2() {
    if (this.showAverage) {
      this.showAverage = false;
    }
    this.showAverage2 = !this.showAverage2;
  }

  geTime(value) {
    return moment.utc(value).valueOf();
  }

  onAfterSetExtremes(event, izoom) {
    if (
      (event.trigger === 'zoom' && !this.fromScoreFocus) ||
      event.trigger === 'navigator'
    ) {
      const time =
        moment(event.min).format('YYYY-MM-DD') +
        '--' +
        moment(event.max).format('YYYY-MM-DD');
      if (izoom) {
        this.iizoom = time;
      }
      this.router.navigate([], {
        queryParams: {
          time: time,
          newsday: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  imgChart(format) {
    if (format === 'jpg') {
      domtoimage
        .toJpeg(document.getElementById('chartcanvas'), { quality: 1 })
        .then(function (dataUrl) {
          const link = document.createElement('a');
          link.download =
            Math.random().toString().replace('0.', '') + '.' + format;
          link.href = dataUrl;
          link.click();
        });
    }
    if (format === 'png') {
      domtoimage
        .toPng(document.getElementById('chartcanvas'), { quality: 1 })
        .then(function (dataUrl) {
          const link = document.createElement('a');
          link.download =
            Math.random().toString().replace('0.', '') + '.' + format;
          link.href = dataUrl;
          link.click();
        });
    }
    if (format === 'svg') {
      domtoimage
        .toSvg(document.getElementById('chartcanvas'), { quality: 1 })
        .then(function (dataUrl) {
          const link = document.createElement('a');
          link.download =
            Math.random().toString().replace('0.', '') + '.' + format;
          link.href = dataUrl;
          link.click();
        });
    }
  }

  exportChartData(format, what) {
    const doubleArrayData = [[]];
    doubleArrayData[0].push('Date');
    let itemName = '';
    if (this.routing.isQuery()) {
      itemName = this.auth.query.name + ' - ';
    } else {
      itemName = this.titolo.entity.name + ' - ';
    }
    what.forEach((type) => {
      if (type === 'volume') {
        doubleArrayData[0].push(itemName + 'Volume');
      } else if (type === 'sentiment') {
        doubleArrayData[0].push(itemName + 'Sentiment');
      } else if (type === 'score') {
        const scoreName =
          this.fromWidget && this.params.score_type
            ? this.params.score_type === 'esg'
              ? 'ESG Score'
              : 'Risk Score'
            : this.auth.getScoreName().short + ' Score';
        if (this.esgSetup) {
          doubleArrayData[0].push(itemName + '' + scoreName);
          doubleArrayData[0].push(itemName + 'E Score');
          doubleArrayData[0].push(itemName + 'S Score');
          doubleArrayData[0].push(itemName + 'G Score');
        } else {
          doubleArrayData[0].push(itemName + '' + scoreName);
        }
      } else if (type === 'price') {
        doubleArrayData[0].push(itemName + 'Price');
      }
    });
    const datesColumn = this.getAllDaysFormatted();
    datesColumn.forEach((date, index) => {
      doubleArrayData.push([date]);
      what.forEach((type) => {
        if (type === 'volume') {
          const rowElement = this.volume_ts.filter((item) => {
            return (
              moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
            );
          });
          if (rowElement.length > 0) {
            doubleArrayData[index + 1].push(rowElement[0].volume);
          } else {
            doubleArrayData[index + 1].push('');
          }
        } else if (type === 'sentiment') {
          const rowElement = this.quotient_ts.filter((item) => {
            return (
              moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
            );
          });
          if (rowElement.length > 0) {
            doubleArrayData[index + 1].push(rowElement[0].quotient);
          } else {
            doubleArrayData[index + 1].push('');
          }
        } else if (type === 'score') {
          if (this.esgSetup) {
            const rowElement = this.score_ts.filter((item) => {
              return (
                moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
              );
            });
            if (rowElement.length > 0) {
              doubleArrayData[index + 1].push(rowElement[0].score);
            } else {
              doubleArrayData[index + 1].push('');
            }
            const rowElementE = this.score_ts_E.filter((item) => {
              return (
                moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
              );
            });
            if (rowElementE.length > 0) {
              doubleArrayData[index + 1].push(rowElementE[0].score);
            } else {
              doubleArrayData[index + 1].push('');
            }
            const rowElementS = this.score_ts_S.filter((item) => {
              return (
                moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
              );
            });
            if (rowElementS.length > 0) {
              doubleArrayData[index + 1].push(rowElementS[0].score);
            } else {
              doubleArrayData[index + 1].push('');
            }
            const rowElementG = this.score_ts_G.filter((item) => {
              return (
                moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
              );
            });
            if (rowElementG.length > 0) {
              doubleArrayData[index + 1].push(rowElementG[0].score);
            } else {
              doubleArrayData[index + 1].push('');
            }
          } else {
            const rowElement = this.score_ts.filter((item) => {
              return (
                moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
              );
            });
            if (rowElement.length > 0) {
              doubleArrayData[index + 1].push(rowElement[0].score);
            } else {
              doubleArrayData[index + 1].push('');
            }
          }
        } else if (type === 'price') {
          const rowElement = this.prices.filter((item) => {
            return (
              moment.utc(item.date).format('YYYY-MM-DD').valueOf() === date
            );
          });
          if (rowElement.length > 0) {
            doubleArrayData[index + 1].push(rowElement[0].price);
          } else {
            doubleArrayData[index + 1].push('');
          }
        }
      });
    });

    const date = new Date();
    const displayDate =
      date.getFullYear() +
      '-' +
      date.getMonth() +
      '-' +
      date.getDate() +
      '_' +
      date.getHours() +
      '-' +
      date.getMinutes() +
      '-' +
      date.getSeconds();
    let itemFileName = '';
    if (this.titolo && this.titolo.entity && this.titolo.entity.type) {
      itemFileName = this.titolo.entity.type;
    }
    const fileName =
      '' + itemFileName + '_' + what.join('_') + '_' + displayDate;
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(doubleArrayData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    if (format === 'xlsx') {
      XLSX.writeFile(wb, fileName + '.xlsx');
    } else if (format === 'csv') {
      XLSX.writeFile(wb, fileName + '.csv');
    }

    // this.yukkApi.getMulty(this.params, format, what).subscribe(result => {
    //   let title = '';
    //   if (this.routing.isFolio()) {
    //     title = this.auth.folio ? this.auth.folio.content : '';
    //   } else if (this.routing.isQuery()) {
    //     title = this.auth.query ? this.auth.query.name : '';
    //   } else if (this.routing.isSearch()) {
    //     title = 'search_query';
    //   } else {
    //     title = this.keyd;
    //   }
    //   FileSaver.saveAs(result, title + '_' + what.join('_') + '.' + format);
    // });
  }

  inAverage() {
    if (this.period1 > 200) {
      this.period1 = 200;
    }
    if (this.period1 < 1) {
      this.period1 = 1;
    }
    this.router.navigate([], {
      queryParams: {
        period1: this.period1,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  inAverage2() {
    if (this.period2 > 200) {
      this.period2 = 200;
    }
    if (this.period2 < 1) {
      this.period2 = 1;
    }
    this.router.navigate([], {
      queryParams: {
        period2: this.period2,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  goFully() {
    let fully = true;
    if (this.params.fully) {
      fully = null;
    }
    this.router.navigate([], {
      queryParams: {
        fully: fully,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  iAnnotation() {
    let annotation = true;
    if (this.params.annotation) {
      annotation = null;
    }
    this.router.navigate([], {
      queryParams: {
        annotation: annotation,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  // exportEvent(format) {
  //   let endpointType;
  //   if (this.routing.isFolio()) {
  //     endpointType = 'folio';
  //   } else if (this.routing.isSearch() || this.routing.isQuery()) {
  //     endpointType = 'query';
  //   } else {
  //     endpointType = 'entity';
  //   }
  //   this.yukkApi.exportEvent(this.params, endpointType).subscribe(result => {
  //     FileSaver.saveAs(result, 'event_export' + '.' + format);
  //   });
  // }

  exportAudit() {
    const today = moment().format('YYYY-MM-DD');
    this.auth.getAuditsXLSX().subscribe((result) => {
      FileSaver.saveAs(result, `newsaudits_export_${today}.xlsx`);
    });
  }

  takeScreenshot() {
    setTimeout(() => {
      const today = moment().format('YYYY-MM-DD');
      this.h2c(document.body).then(function (canvas) {
        canvas.toBlob(function (blob) {
          FileSaver.saveAs(blob, `YukkaLab_${today}.png`);
        });
      });
    }, 500);
  }
}
