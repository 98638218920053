/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, ViewEncapsulation } from '@angular/core';
import { YukkApi } from '../../../service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../service/routing.service';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-news-trending',
  templateUrl: './news-trending.component.html',
  styleUrls: ['./news-trending.component.scss'],
})
export class NewsTrendingComponent {
  params: any;
  previousValue: any;

  searchTerm: any;

  indices = [];
  selectedIndexes: string[];
  industries = [];
  selectedIndustries: string[];
  trends = ['all', 'Bear', 'Bull'];
  selectedTrend: string;
  // scores = ['A', 'B', 'C', 'D'];
  // selectedScores: any;
  time_horizon = [
    { value: '1', label: 'Today' },
    { value: '2', label: '24 hours' },
    { value: '7', label: 'Week' },
    { value: '30', label: 'Month' },
    { value: '90', label: 'Quarter' },
    { value: '180', label: '6 months' },
    { value: '365', label: 'Year' },
  ];
  selectedTimeHorizon: string;
  countries = [];
  selectedCountries: string[];

  entitiesList = [];

  events: [];
  selectedEvents = [];

  nodata: boolean;
  loading: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private yukkaApi: YukkApi,
    public routing: RoutingService,
  ) {
    this.selectedIndexes = ['STOXX 600'];
    this.selectedIndustries = [];
    this.selectedTrend = 'all';
    // this.selectedScores = ['A', 'B', 'C', 'D'];
    this.selectedTimeHorizon = '7';
    this.selectedCountries = [];
    this.selectedEvents = [];

    this.yukkaApi.getTrendingCountries().subscribe((res) => {
      this.countries = this.countries.concat(
        res.entities.map((entry) => {
          return { value: entry['alpha_id'], label: entry['name'] };
        }),
      );
    });

    this.yukkaApi.getIndexes().subscribe((res) => {
      this.indices = this.indices.concat(res.indexes);
    });

    this.yukkaApi.getIndustries().subscribe((res) => {
      this.industries = this.industries.concat(
        res.entities.map((entry) => {
          return { value: entry['alpha_id'], label: entry['name'] };
        }),
      );
    });

    this.yukkaApi.getHierarchyInfo('event', {}).subscribe((res) => {
      this.events = JSON.parse(JSON.stringify(res.event_structure));
    });

    this.route.queryParams.subscribe((qparam) => {
      this.params = qparam;

      if (this.routing.reFresh(this.params, this.previousValue, ['sort'])) {
        if (this.params.sort !== 'abc' && this.params.sort !== '-abc') {
          this.onSelectionChange();
        }
      }

      this.previousValue = qparam;
    });
  }

  onSelectionChange(type = null, opened = null) {
    if (type !== 'event' || !opened) {
      this.nodata = false;
      this.loading = true;

      const eventsIds = this.selectedEvents.map((el) => {
        return el.id;
      });

      // if (
      //   type === 'country' &&
      //   this.selectedCountry &&
      //   this.selectedCountry !== 'all'
      // ) {
      //   this.selectedIndex = 'all';
      // } else if (
      //   type === 'index' &&
      //   this.selectedIndex &&
      //   this.selectedIndex !== 'all'
      // ) {
      //   this.selectedCountry = 'all';
      // }

      let sort_type = 'sentiment';
      let sort_value = 'desc';

      if (this.params.sort && this.params.sort.charAt(0) === '-') {
        sort_value = 'asc';
      } else if (this.params.sort && this.params.sort.charAt(0) !== '-') {
        sort_value = 'desc';
      }

      if (this.params.sort === 'bull_bear') {
        sort_type = 'bull_bear_score';
      }
      if (this.params.sort === '-bull_bear') {
        sort_type = 'bull_bear_score';
      }
      if (this.params.sort === 'vola') {
        sort_type = 'stock_vola';
      }
      if (this.params.sort === '-vola') {
        sort_type = 'stock_vola';
      }
      if (this.params.sort === 'abc') {
        sort_type = 'sentiment';
      }
      if (this.params.sort === '-abc') {
        sort_type = 'sentiment';
      }
      if (this.params.sort === 'price') {
        sort_type = 'stock_price';
      }
      if (this.params.sort === '-price') {
        sort_type = 'stock_price';
      }
      if (this.params.sort === 'esg') {
        sort_type = 'esg';
      }
      if (this.params.sort === '-esg') {
        sort_type = 'esg';
      }
      if (this.params.sort === 'esg_risk') {
        sort_type = 'esg_risk';
      }
      if (this.params.sort === '-esg_risk') {
        sort_type = 'esg_risk';
      }
      if (this.params.sort === 'general_risk') {
        sort_type = 'general_risk';
      }
      if (this.params.sort === '-general_risk') {
        sort_type = 'general_risk';
      }
      if (this.params.sort === 'credit_risk') {
        sort_type = 'credit_watch';
      }
      if (this.params.sort === '-credit_risk') {
        sort_type = 'credit_watch';
      }
      if (this.params.sort === 'immediate_risk') {
        sort_type = 'immediate_risk';
      }
      if (this.params.sort === '-immediate_risk') {
        sort_type = 'immediate_risk';
      }
      if (this.params.sort === 'eb_sim') {
        sort_type = 'eb_sim';
      }
      if (this.params.sort === '-eb_sim') {
        sort_type = 'eb_sim';
      }
      if (this.params.sort === 'eb_sim_risk') {
        sort_type = 'eb_sim_risk';
      }
      if (this.params.sort === '-eb_sim_risk') {
        sort_type = 'eb_sim_risk';
      }
      if (this.params.sort === 'senti') {
        sort_type = 'sentiment';
      }
      if (this.params.sort === '-senti') {
        sort_type = 'sentiment';
      }
      if (this.params.sort === 'count') {
        sort_type = 'volume';
      }
      if (this.params.sort === '-count') {
        sort_type = 'volume';
      }

      const myParams = {
        amount: 100,
        stock_index: this.selectedIndexes,
        industry: this.selectedIndustries,
        time: this.selectedTimeHorizon,
        country: this.selectedCountries,
        columns: [
          'bull_bear_score',
          'esg',
          'esg_risk',
          'credit_watch',
          'general_risk',
          'immediate_risk',
          'eb_sim',
          'eb_sim_risk',
          'stock_price',
          'stock_vola',
          'stock_price_delta',
          'sentiment',
          'volume',
        ],
        sort_type: sort_type,
        sort_value: sort_value,
        events: eventsIds,
      };

      this.yukkaApi.trending(myParams).subscribe(
        (res) => {
          this.entitiesList = res.trending.map((el) => {
            const trend_type =
              el.values.bull_bear_score !== null
                ? el.values.bull_bear_score > 5
                  ? 'bull'
                  : 'bear'
                : null;
            const trend_value =
              el.values.bull_bear_score !== null
                ? Number(el.values.bull_bear_score.toFixed(1))
                : null;
            const volatility_value =
              el.values.stock_vola !== null
                ? Math.round(el.values.stock_vola * 100)
                : null;
            const volatility_type =
              volatility_value && volatility_value > 50 ? 'dolphin' : 'whale';
            return {
              name: el.entity_info.name,
              compound_key: el.entity_info.compound_key,
              type: el.entity_info.type,
              alpha_id: el.entity_info.alpha_id,
              logo:
                el.entity_info &&
                el.entity_info.media &&
                el.entity_info.media.uni
                  ? el.entity_info.media.uni
                  : null,
              trend_value: trend_value,
              trend_type: trend_type,
              volatility_value: volatility_value,
              volatility_type: volatility_type,
              country_name: el.country_name ? el.country_name : '',
              country_id: el.country_alpha_id
                ? el.country_alpha_id.split(':')[1].toUpperCase()
                : '',
              price: el.values.stock_price,
              currency: el.currency,
              price_delta:
                el.values.stock_price_delta !== null
                  ? Math.round(el.values.stock_price_delta * 100)
                  : null,
              esg_score:
                el.values.esg !== null
                  ? Number(el.values.esg.toFixed(1))
                  : null,
              esg_risk_score:
                el.values.esg_risk !== null
                  ? Number(el.values.esg_risk.toFixed(1))
                  : null,
              credit_risk_score:
                el.values.credit_watch !== null
                  ? Number(el.values.credit_watch.toFixed(1))
                  : null,
              immediate_risk_score:
                el.values.immediate_risk !== null
                  ? Number(el.values.immediate_risk.toFixed(1))
                  : null,
              general_risk_score:
                el.values.general_risk !== null
                  ? Number(el.values.general_risk.toFixed(1))
                  : null,
              eb_sim_score:
                el.values.eb_sim !== null
                  ? Number(el.values.eb_sim.toFixed(1))
                  : null,
              eb_sim_risk_score:
                el.values.eb_sim_risk !== null
                  ? Number(el.values.eb_sim_risk.toFixed(1))
                  : null,
              sentiment:
                el.values.sentiment !== null ? el.values.sentiment : null,
              volume: el.values.volume !== null ? el.values.volume : null,
            };
          });

          if (this.entitiesList.length === 0) {
            this.nodata = true;
          }

          this.loading = false;
        },
        () => {
          this.nodata = true;
          this.loading = false;
        },
      );
    }
  }

  filteredEntitiesList() {
    const filteredEntities = this.entitiesList;
    // const filteredEntities = this.entitiesList.filter(entity => {
    //   if (!(this.selectedScores.includes('A') && this.selectedScores.includes('B') && this.selectedScores.includes('C') && this.selectedScores.includes('D'))) {
    //     return this.selectedScores.includes(entity.score_category);
    //   } else {
    //     return true;
    //   }
    // });
    return filteredEntities;
  }

  // getScoreInfo(score) {
  //   if (score === 'D') {
  //     return '0-2.5';
  //   } else if (score === 'C') {
  //     return '2.5-5';
  //   } else if (score === 'B') {
  //     return '5-7.5';
  //   } else if (score === 'A') {
  //     return '7.5-10';
  //   }
  // }
}
