/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, OnInit, Input } from '@angular/core';
import { YukkApi } from '../../../../service/yukkapi.service';
import { Router, ActivatedRoute } from '@angular/router';
import { RoutingService } from '../../../../service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { UntypedFormControl } from '@angular/forms';
import { PortfolioNameComponent } from '../../news-portfolio/portfolio-name/portfolio-name.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-trending-column',
  templateUrl: './trending-column.component.html',
  styleUrls: ['./trending-column.component.scss'],
})
export class TrendingColumnComponent implements OnInit {
  @Input() trending: any;
  @Input() searchTerm: any;
  @Input() loading: any;
  @Input() nodata: any;

  // arraytrend: any;
  // myOrder: any;
  // previousValue: any;
  // rsub: any;

  qparams: any;

  params = {
    sort: 'trend',
  };
  myOrder: any;

  /**
   * columns to display
   */
  ifilter: any;

  /**
   * columns array
   */
  array_filter = [
    'Country',
    'BB Score',
    'ESG Score',
    'ESGR Score',
    'CR Score',
    'GR Score',
    'IR Score',
    'EB-SIM Score',
    'EB-SIMR Score',
    'Price',
    'Volatility',
    'Price Change',
    'Sentiment',
    'Volume',
  ];

  constructor(
    private yukkApi: YukkApi,
    private router: Router,
    private route: ActivatedRoute,
    private routing: RoutingService,
    private auth: AuthService,
    public dialog: MatDialog,
  ) {
    this.array_filter = [];
    this.array_filter.push('Country');
    if (auth.scorelabSettings.bbAccess) {
      this.array_filter.push('BB Score');
    }
    if (auth.scorelabSettings.esgAccess) {
      this.array_filter.push('ESG Score');
    }
    if (auth.scorelabSettings.esgRiskAccess) {
      this.array_filter.push('ESGR Score');
    }
    if (auth.scorelabSettings.creditRiskAccess) {
      this.array_filter.push('CR Score');
    }
    if (auth.scorelabSettings.generalRiskAccess) {
      this.array_filter.push('GR Score');
    }
    if (auth.scorelabSettings.immediateRiskAccess) {
      this.array_filter.push('IR Score');
    }
    if (auth.scorelabSettings.eb_simAccess) {
      this.array_filter.push('EB-SIM Score');
      this.array_filter.push('EB-SIMR Score');
    }
    this.array_filter.push('Price');
    this.array_filter.push('Volatility');
    this.array_filter.push('Price Change');
    if (auth.scorelabSettings.sentimentAccess) {
      this.array_filter.push('Sentiment');
    }
    this.array_filter.push('Volume');

    this.route.queryParams.subscribe((params) => {
      this.qparams = params;
      this.ifilter = new UntypedFormControl(this.array_filter);
      this.auth.getUserSettings('trendColumns').subscribe((result) => {
        if (result.entry) {
          let resultObj = JSON.parse(result.entry);
          resultObj = resultObj.filter((item) => {
            return this.array_filter.includes(item);
          });
          this.ifilter = new UntypedFormControl(resultObj);
        } else {
          this.ifilter = new UntypedFormControl(this.array_filter);
        }
      });
    });
  }

  ngOnInit() {
    // this.loading = false;

    this.auth.getUserSettings('trendColumns').subscribe((result) => {
      if (result.entry) {
        let resultObj = JSON.parse(result.entry);
        resultObj = resultObj.filter((item) => {
          return this.array_filter.includes(item);
        });
        this.ifilter = new UntypedFormControl(resultObj);
      } else {
        this.ifilter = new UntypedFormControl(this.array_filter);
      }
    });

    if (this.params.sort) {
      if (this.params.sort === 'bull_bear') {
        this.myOrder = '-trend_value';
      }
      if (this.params.sort === '-bull_bear') {
        this.myOrder = 'trend_value';
      }
      if (this.params.sort === 'vola') {
        this.myOrder = '-volatility_value';
      }
      if (this.params.sort === '-vola') {
        this.myOrder = 'volatility_value';
      }
      if (this.params.sort === 'abc') {
        this.myOrder = 'name';
      }
      if (this.params.sort === '-abc') {
        this.myOrder = '-name';
      }
      if (this.params.sort === 'price') {
        this.myOrder = '-price_delta';
      }
      if (this.params.sort === '-price') {
        this.myOrder = 'price_delta';
      }
      if (this.params.sort === 'esg') {
        this.myOrder = '-esg_score';
      }
      if (this.params.sort === '-esg') {
        this.myOrder = 'esg_score';
      }
      if (this.params.sort === 'esg_risk') {
        this.myOrder = '-esg_risk_score';
      }
      if (this.params.sort === '-esg_risk') {
        this.myOrder = 'esg_risk_score';
      }
      if (this.params.sort === 'general_risk') {
        this.myOrder = '-general_risk_score';
      }
      if (this.params.sort === '-general_risk') {
        this.myOrder = 'general_risk_score';
      }
      if (this.params.sort === 'credit_risk') {
        this.myOrder = '-credit_risk_score';
      }
      if (this.params.sort === '-credit_risk') {
        this.myOrder = 'credit_risk_score';
      }
      if (this.params.sort === 'immediate_risk') {
        this.myOrder = '-immediate_risk_score';
      }
      if (this.params.sort === '-immediate_risk') {
        this.myOrder = 'immediate_risk_score';
      }
      if (this.params.sort === 'eb_sim') {
        this.myOrder = '-eb_sim_score';
      }
      if (this.params.sort === '-eb_sim') {
        this.myOrder = 'eb_sim_score';
      }
      if (this.params.sort === 'eb_sim_risk') {
        this.myOrder = '-eb_sim_risk_score';
      }
      if (this.params.sort === '-eb_sim_risk') {
        this.myOrder = 'eb_sim_risk_score';
      }
      if (this.params.sort === 'senti') {
        this.myOrder = '-sentiment';
      }
      if (this.params.sort === '-senti') {
        this.myOrder = 'sentiment';
      }
      if (this.params.sort === 'count') {
        this.myOrder = '-volume';
      }
      if (this.params.sort === '-count') {
        this.myOrder = 'volume';
      }
    } else {
      this.myOrder = 'trend_value';
    }
    // this.myOrder = 'sentiment_delta';
    // const newParams = Object.assign({}, this.params, {how: this.myOrder, time: 30});
    // this.params = newParams;
    // if (this.routing.reFresh(this.params, this.previousValue, ['entity', 'time', 'lang', 'feed', 'categories', 'continents', 'countries', 'ranks', 'how'])) {
    //   this.loading = true;
    //   this.trending = [];
    //   this.rsub = this.yukkApi.trending(this.params, this.entity).subscribe(result => {
    //     this.trending = result.trending;
    //     this.loading = false;
    //   });
    // }
    // this.previousValue = this.params;
  }

  /**
   * apply chosen sorting option
   */
  goSort(value) {
    if (this.params.sort === value) {
      this.params.sort = '-' + value;
    } else {
      this.params.sort = value;
    }
    if (this.params.sort === 'bull_bear') {
      this.myOrder = '-trend_value';
    }
    if (this.params.sort === '-bull_bear') {
      this.myOrder = 'trend_value';
    }
    if (this.params.sort === 'vola') {
      this.myOrder = '-volatility_value';
    }
    if (this.params.sort === '-vola') {
      this.myOrder = 'volatility_value';
    }
    if (this.params.sort === 'abc') {
      this.myOrder = 'name';
    }
    if (this.params.sort === '-abc') {
      this.myOrder = '-name';
    }
    if (this.params.sort === 'price') {
      this.myOrder = '-price_delta';
    }
    if (this.params.sort === '-price') {
      this.myOrder = 'price_delta';
    }
    if (this.params.sort === 'esg') {
      this.myOrder = '-esg_score';
    }
    if (this.params.sort === '-esg') {
      this.myOrder = 'esg_score';
    }
    if (this.params.sort === 'esg_risk') {
      this.myOrder = '-esg_risk_score';
    }
    if (this.params.sort === '-esg_risk') {
      this.myOrder = 'esg_risk_score';
    }
    if (this.params.sort === 'general_risk') {
      this.myOrder = '-general_risk_score';
    }
    if (this.params.sort === '-general_risk') {
      this.myOrder = 'general_risk_score';
    }
    if (this.params.sort === 'credit_risk') {
      this.myOrder = '-credit_risk_score';
    }
    if (this.params.sort === '-credit_risk') {
      this.myOrder = 'credit_risk_score';
    }
    if (this.params.sort === 'immediate_risk') {
      this.myOrder = '-immediate_risk_score';
    }
    if (this.params.sort === '-immediate_risk') {
      this.myOrder = 'immediate_risk_score';
    }
    if (this.params.sort === 'eb_sim') {
      this.myOrder = '-eb_sim_score';
    }
    if (this.params.sort === '-eb_sim') {
      this.myOrder = 'eb_sim_score';
    }
    if (this.params.sort === 'eb_sim_risk') {
      this.myOrder = '-eb_sim_risk_score';
    }
    if (this.params.sort === '-eb_sim_risk') {
      this.myOrder = 'eb_sim_risk_score';
    }
    if (this.params.sort === 'sent') {
      this.myOrder = '-sentiment';
    }
    if (this.params.sort === '-sent') {
      this.myOrder = 'sentiment';
    }
    if (this.params.sort === 'count') {
      this.myOrder = '-volume';
    }
    if (this.params.sort === '-count') {
      this.myOrder = 'volume';
    }

    this.router.navigate([], {
      queryParams: {
        sort: this.params.sort,
      },
      queryParamsHandling: 'merge',
      replaceUrl: false,
    });
  }

  /**
   * navigate to company page on item click
   */
  navigateTo(item) {
    if (!this.routing.isMobile()) {
      // const tagItem = 'm:' + item.type + ':' + item.alpha_id;
      // const tag = this.qparams.tag === tagItem ? null : encodeURI(tagItem);
      this.router.navigate(['/cockpit/news/market/chart'], {
        queryParams: {
          type: item.type,
          id: item.alpha_id,
          tag: null,
          news: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          newstype: null,
          focused_score: this.auth.scorelabSettings.defaultScore
            ? this.auth.scorelabSettings.defaultScore
            : null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      this.router.navigate(['/mobile/news/market/chart'], {
        queryParams: {
          type: item.type,
          id: item.alpha_id,
          tag: null,
          news: null,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          newstype: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * changed columns to be displayed
   */
  iFilter(event) {
    if (event === false) {
      const trendColumns = this.ifilter.value;
      const trendColumnsValue = JSON.stringify(trendColumns);
      this.auth.setUserSettings('trendColumns', trendColumnsValue).subscribe();
    }
  }

  /**
   * check if column should be displayed
   */
  iTool(value) {
    if (this.ifilter) {
      let showColumn = true;
      if (!this.auth.scorelabSettings.bbAccess) {
        if (value === 'BB Score') {
          showColumn = false;
        }
      }
      if (!this.auth.scorelabSettings.esgAccess) {
        if (value === 'ESG Score') {
          showColumn = false;
        }
      }
      if (!this.auth.scorelabSettings.esgRiskAccess) {
        if (value === 'ESGR Score') {
          showColumn = false;
        }
      }
      if (!this.auth.scorelabSettings.creditRiskAccess) {
        if (value === 'CR Score') {
          showColumn = false;
        }
      }
      if (!this.auth.scorelabSettings.generalRiskAccess) {
        if (value === 'GR Score') {
          showColumn = false;
        }
      }
      if (!this.auth.scorelabSettings.immediateRiskAccess) {
        if (value === 'IR Score') {
          showColumn = false;
        }
      }
      if (!this.auth.scorelabSettings.eb_simAccess) {
        if (value === 'EB-SIM Score') {
          showColumn = false;
        }
        if (value === 'EB-SIMR Score') {
          showColumn = false;
        }
      }
      if (!this.auth.scorelabSettings.sentimentAccess) {
        if (value === 'Sentiment') {
          showColumn = false;
        }
      }
      return showColumn && this.ifilter.value.includes(value);
    } else {
      return false;
    }
  }

  onMouseEnterRow(item) {
    item.visible = true;
  }

  onMouseLeaveRow(item) {
    item.visible = false;
  }

  appendItem(option, item) {
    this.dialog.open(PortfolioNameComponent, {
      data: {
        action: option,
        entity: item,
      },
    });
  }
}
