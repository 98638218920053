/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';

//service
import { YukkApi } from 'src/app/service/yukkapi.service';
import { ColorScorePipe } from 'src/app/pipe/color-score.pipe';
import { ColorScore2Pipe } from 'src/app/pipe/color-score2.pipe';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { AuthService } from 'src/app/service/auth.service';
import { RoutingService } from 'src/app/service/routing.service';

@Component({
  selector: 'app-chat-charts-ranking',
  templateUrl: './chat-charts-ranking.component.html',
  styleUrls: ['./chat-charts-ranking.component.scss'],
})
export class ChatChartsRankingComponent implements OnInit {
  @Input() chartEntities: string[];
  @Input() childRankingScoreType: string;
  @Input() childRankingScoreTitle: string;
  @Input() chartEntityNames: string[] = [];
  @Input() childRankingTimeframe: string;

  rankingTableData: any[] = [];
  loading: boolean;
  constructor(
    private auth: AuthService,
    private yukkApi: YukkApi,
    public routing: RoutingService,
  ) {}

  ngOnInit(): void {
    this.getGraphRanking();
  }

  getGraphRanking() {
    this.loading = true;
    this.yukkApi
      .getEntitiesInfo(this.chartEntities ? this.chartEntities : [], 'array')
      .subscribe((entitiy) => {
        const entitiesRes = Object.values(entitiy).filter((element) => {
          return element !== null;
        }) as any[];

        const content = entitiesRes.map((entity: any) => entity.compound_key);
        if (this.childRankingScoreType) {
          this.yukkApi
            .scoresTimeSeries(
              this.childRankingScoreType === 'sentiment'
                ? 'sentiment'
                : 'score',
              Object.assign(
                {},
                {
                  usePortfolio: true,
                  itemized: true,
                  requestScore: this.childRankingScoreType,
                  custom_payload: content,
                  time: this.childRankingTimeframe,
                },
              ),
              'chart',
            )

            .subscribe((res) => {
              this.generateRankingTableData(res, entitiesRes);
              this.loading = false;
            });
        }
      });
  }

  private generateRankingTableData(scores: any, entities: any[]) {
    const chartData = [];
    if (this.childRankingScoreType === 'sentiment') {
      scores?.quotient_ts_itemized.forEach((element) => {
        const sentimentLastScore =
          element.quotient_ts[element.quotient_ts.length - 1]?.quotient;

        chartData.push({
          name: entities.find(
            (entitiy: any) =>
              entitiy.compound_key === element.entity_info.compound_key,
          )?.name,
          score: sentimentLastScore,
          scoreType: this.childRankingScoreType,
        });
      });
    } else {
      scores.forEach((element) => {
        chartData.push({
          name: entities.find(
            (entitiy: any) =>
              entitiy.compound_key === element.entity_info.compound_key,
          )?.name,
          score: element.score_ts_last_score.score.toFixed(2),
          scoreType: this.childRankingScoreType,
        });
      });
    }

    this.rankingTableData = chartData;
  }

  getScoreColor(item) {
    if (item.scoreType === 'sentiment') {
      const filterPipe = new ColorPipe(this.routing);
      return filterPipe.transform(item.score);
    } else {
      const filterPipe =
        this.auth.getScoreType(item.scoreType) === 'pos'
          ? new ColorScore2Pipe(this.routing)
          : new ColorScorePipe(this.routing);
      return filterPipe.transform(item.score);
    }
  }
}
