/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AIMessage,
  SourceDoc,
  FactWithSource,
} from '../interfaces/chart-details.interface';

@Component({
  selector: 'app-chat-message-facts',
  styleUrls: ['./chat-message-facts.component.scss'],
  templateUrl: './chat-message-facts.component.html',
})
export class MessageFactsComponent implements OnInit {
  @Input() aiMessage: AIMessage;
  @Output() citationClicked = new EventEmitter<{
    article: SourceDoc;
  }>();
  joinedMessages: string;
  factsWithSources: FactWithSource[] = [];
  hoveredDoc: SourceDoc | null = null;
  tooltipPosition: { left: number; top: number } | null = null;
  private hideTooltipTimeout: any;

  private readonly TOOLTIP_HEIGHT = 192;
  private readonly TOOLTIP_WIDTH = 256;
  private readonly OFFSET = 10;
  private readonly INPUT_HEIGHT = 60;
  private readonly EXTRA_OFFSET = 20;

  constructor() {}

  ngOnInit(): void {
    this.factsWithSources = this.hasFacts()
      ? this.processFactsWithSources()
      : [];
    this.joinedMessages = !this.hasFacts() ? this.processMessages() : '';
  }

  private hasFacts(): boolean {
    return this.aiMessage.facts && this.aiMessage.facts.length > 0;
  }
  formatFact(fact: string): string {
    if (!fact) return '';
    return fact
      .trim()
      .replace(/\n\n/g, '<br><br>')
      .replace(/\n/g, '<br>')
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
      .replace(/\*(.*?)\*/g, '<i>$1</i>')
      .replace(/###\s*(\w+)/g, '<h3>$1:</h3>')
      .replace(/####\s*(\w+)/g, '<h4>$1:</h4>');
  }

  processMessages(): string {
    const message = this.aiMessage.messages
      .map((msg: string) => msg.trim())
      .join(' ')
      .replace(/\n\n/g, '<br><br>')
      .replace(/\n/g, '<br>');
    return message;
  }

  processFactsWithSources(): FactWithSource[] {
    const factsWithSource = this.aiMessage?.facts?.map((fact) => {
      return {
        fact: fact.fact,
        source_docs: fact.source_doc_ids.map((sourceDocId: string) => {
          const sourceDoc = this.aiMessage.source_documents_events?.find(
            (doc: SourceDoc) => doc.id === sourceDocId,
          );
          return {
            id: sourceDocId,
            title: sourceDoc?.title,
            publish_time: sourceDoc?.publish_time,
            provider: sourceDoc?.provider,
            eventNames: sourceDoc?.eventNames,
            entityNames: sourceDoc?.entityNames,
            citation: sourceDoc?.citation,
          };
        }),
      };
    });

    return factsWithSource;
  }

  onCitationClick(article: SourceDoc): void {
    this.citationClicked.emit({ article });
  }

  onCitationMouseEnter(event: MouseEvent, doc: SourceDoc): void {
    clearTimeout(this.hideTooltipTimeout);
    this.hoveredDoc = doc;
    this.tooltipPosition = this.calculateTooltipPosition(event);
  }

  onCitationMouseLeave(): void {
    this.hideTooltipTimeout = setTimeout(() => this.hideTooltip(), 50);
  }

  @HostListener('document:mousewheel', ['$event'])
  onDocumentMousewheelEvent(): void {
    if (this.hoveredDoc) {
      this.hideTooltip();
    }
  }

  // calculate the tooltip position based on citation position and chat wrapper dimensions
  // calculate the top position based on the space available above and below the citation
  // calculate the left position based on the space available on the left and right side of the chat wrapper
  private calculateTooltipPosition(event: MouseEvent): {
    left: number;
    top: number;
  } {
    const citationRect = (event.target as HTMLElement).getBoundingClientRect();
    const chatWrapperRect = this.getChatWrapperRect(
      event.target as HTMLElement,
    );

    const topPosition = this.calculateTopPosition(citationRect);
    const leftPosition = this.calculateLeftPosition(
      citationRect,
      chatWrapperRect,
    );

    return { left: leftPosition, top: topPosition };
  }

  private getChatWrapperRect(citationElement: HTMLElement): DOMRect {
    const chatWrapper = citationElement.closest(
      '.chat__wrapper',
    ) as HTMLElement;
    // get the element related current message;
    return chatWrapper.getBoundingClientRect();
  }

  private calculateTopPosition(citationRect: DOMRect): number {
    const spaceBelow =
      window.innerHeight - citationRect.bottom - this.INPUT_HEIGHT;
    return spaceBelow < this.TOOLTIP_HEIGHT
      ? citationRect.top - this.TOOLTIP_HEIGHT - this.OFFSET - this.EXTRA_OFFSET
      : citationRect.bottom + this.OFFSET;
  }

  private calculateLeftPosition(
    citationRect: DOMRect,
    chatWrapperRect: DOMRect,
  ): number {
    const leftPosition =
      citationRect.left + citationRect.width / 2 - this.TOOLTIP_WIDTH / 2;

    if (leftPosition < chatWrapperRect.left + this.OFFSET) {
      return chatWrapperRect.left + this.OFFSET;
    } else if (
      leftPosition + this.TOOLTIP_WIDTH >
      chatWrapperRect.right - this.OFFSET
    ) {
      return (
        chatWrapperRect.right -
        this.TOOLTIP_WIDTH -
        this.OFFSET -
        citationRect.width
      );
    }
    return leftPosition;
  }

  private hideTooltip(): void {
    this.hoveredDoc = null;
    this.tooltipPosition = null;
  }
}
