/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AuthService } from 'src/app/service/auth.service';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { UntypedFormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { debounceTime, map, mergeMap } from 'rxjs/operators';
import { portfolioEvents } from './portfolio-events';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-portfolio-setup',
  templateUrl: './portfolio-setup.component.html',
  styleUrls: ['./portfolio-setup.component.scss'],
})
export class PortfolioSetupComponent implements OnInit {
  params: any;
  portfolioName: string;
  searchFormControl = new UntypedFormControl();
  selectedEntities = [];
  items$: Observable<any>;
  loading: boolean;

  constructor(
    public auth: AuthService,
    private yukkApi: YukkApi,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
  ) {}

  ngOnInit() {
    this.portfolioName = '';
    this.selectedEntities = [];
    this.searchFormControl.setValue('');
    this.searchFormControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => {
        if (value && value.length > 1) {
          this.loading = true;
          this.items$ = this.yukkApi.search(value, false).pipe(
            mergeMap((result) => {
              if (result && result.hits.length === 0) {
                const words = value.split(' ').filter((item) => item !== '');
                return this.yukkApi
                  .search(words[0] ? words[0] : ' ', false)
                  .pipe(
                    map((result2) => {
                      this.loading = false;
                      return result2;
                    }),
                  );
              } else {
                this.loading = false;
                return of(result);
              }
            }),
          );
        }
      });
  }

  optSelect(option) {
    this.searchFormControl.setValue('');

    this.selectedEntities.push({
      name: option.entity.name,
      type: option.entity.type,
      alpha_id: option.entity.alpha_id,
    });
  }

  removeEntity(id) {
    this.selectedEntities = this.selectedEntities.filter(
      (e) => e.alpha_id !== id,
    );
  }

  iClose() {
    this.dialog.closeAll();
  }

  iMatch(entity) {
    if (entity.matched_form) {
      return (
        entity.name.charAt(0).toLowerCase() !== entity.matched_form.charAt(0)
      );
    }
  }

  numberFormat(num) {
    // eslint-disable-next-line
    // @ts-ignore
    return Intl.NumberFormat('en', { notation: 'compact' }).format(num);
  }

  isValid() {
    if (this.portfolioName.trim() && this.selectedEntities.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  createPortfolio() {
    if (this.isValid()) {
      this.auth.newFolio(this.portfolioName).subscribe((result) => {
        this.auth
          .addEntitiesToPortfolios(
            this.selectedEntities.map((entity) => {
              return entity.type + ':' + entity.alpha_id;
            }),
            [result.id],
          )
          .subscribe(() => {
            const newsletter = {
              active: true,
              contents: ['top_news'],
              delivery_settings: {
                frequency: 'MONTHLY',
              },
              dispObjects: [
                {
                  payload: result.id,
                  type: 'portfolio',
                  view: this.portfolioName,
                },
              ],
              displayables: ['ylrn:displayable:portfolio:' + result.id],
              frequency: 'MONTHLY',
              header_image:
                'https://yl-newsletters-logos-v1.s3.amazonaws.com/Logo_YUKKA_News-Analytics_Transparent.png',
              hide_sentiment_bars: false,
              logo: '',
              news_settings: {
                top_news_max_number: 20,
              },
              recipients: [],
              stories_max_number_default: 10,
              title: this.portfolioName + ' - Newsletter',
              top_news_max_number: 20,
              top_news_max_number_default: 7,
              uid: '',
            };
            this.auth.editNewsletter(newsletter).subscribe((result2) => {
              if (result2 && result2.uid) {
                const data = {
                  uid: result2.uid,
                };
                this.auth.generateNewsletter(data).subscribe();
              }
            });
            let alertEvents = [];
            if (this.auth.accountInfo.interest === 'esg') {
              alertEvents = portfolioEvents.esgLab;
            } else if (this.auth.accountInfo.interest === 'risk') {
              alertEvents = portfolioEvents.riskLab;
            } else if (this.auth.accountInfo.interest === 'news') {
              alertEvents = portfolioEvents.newsLab;
            } else if (this.auth.accountInfo.interest === 'invest') {
              alertEvents = portfolioEvents.investLab;
            }
            const payloadObject = {
              active: true,
              schedule: '1d',
              channel: 'email',
              layout: 'digest',
              name: 'Alert for ' + this.portfolioName,
              additional_recipients: [],
              event_alert_metadata: {
                portfolio_id: result.id,
                entities: [],
                event_ids: alertEvents,
                threshold: 0,
                threshold_setting: 'pass_through',
                additional_params: {},
              },
            };
            this.auth.addAlert(payloadObject).subscribe((result2) => {
              this.auth.triggerAlert(result2.uid).subscribe();
            });
            this.iClose();
            this.snackBar.open(
              'We are generating your portfolio, preparing the monthly newsletter and daily alert...',
              'OK',
              { duration: 5000 },
            );
          });
      });
    }
  }

  defaultPortfolio() {
    this.iClose();
  }
}
