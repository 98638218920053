/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PortfolioNameComponent } from '../portfolio-name/portfolio-name.component';
import { PortfolioIndexComponent } from '../portfolio-index/portfolio-index.component';
import { PortfolioImportComponent } from '../portfolio-import/portfolio-import.component';
import { PortfolioShareComponent } from '../portfolio-share/portfolio-share.component';
import { ActivatedRoute, Router } from '@angular/router';
import { YukkApi } from 'src/app/service/yukkapi.service';
import { RoutingService } from 'src/app/service/routing.service';
import { AuthService } from 'src/app/service/auth.service';
import { ConfigService } from 'src/app/service/config.service';
import * as FileSaver from 'file-saver';
import { ColorPipe } from 'src/app/pipe/color.pipe';
import { ColorRatioPipe } from 'src/app/pipe/colorRatio.pipe';
import { ColorScorePipe } from 'src/app/pipe/color-score.pipe';
import { ColorScore2Pipe } from 'src/app/pipe/color-score2.pipe';
import { of, concat, Subject } from 'rxjs';
import { catchError, toArray, takeUntil } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

/**
 * here you can import multiple entities via isin or search by
 */
@Component({
  selector: 'app-portfolio-view',
  templateUrl: './portfolio-view.component.html',
  styleUrls: ['./portfolio-view.component.scss'],
})
export class PortfolioViewComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  public Math = Math;

  /**
   * view chosen for the portfolio board (list or mosaic)
   */
  portfolioView: any;

  /**
   * query parameters url
   */
  params: any;

  /**
   * portfolio content
   */
  items: any;
  itemsCountMin: any;
  itemsCountMax: any;
  itemsTrendMax: any;
  itemsTrendMin: any;
  itemsRatioMax: any;
  itemsRatioMin: any;
  itemsScoreMin: any;
  itemsScoreMax: any;
  itemsLoaded: boolean;

  /**
   * portfolio content in the news-table's format
   */
  itemsFiltered: any;

  /**
   * project enviroment
   */
  project: string;

  /**
   * sort order
   */
  myOrder: string;

  /**
   * refresh by parameters
   */
  previousValue: any;

  /**
   * id of the entity under mouse cursor
   */
  currentEntity: any;

  /**
   * query parameters subscribe, request data api and sortby
   */
  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private yukkApi: YukkApi,
    public routing: RoutingService,
    public auth: AuthService,
    private router: Router,
    public config: ConfigService,
    public snackBar: MatSnackBar,
  ) {
    this.project = this.config.appConfig.routing.project;
    this.route.queryParams.subscribe((params) => {
      this.params = params;
      if (params.sort) {
        if (!(auth.scorelabSettings.defaultScore === 'sentiment')) {
          if (params.sort === 'score') {
            this.myOrder = '-score';
          }
          if (params.sort === '-score') {
            this.myOrder = 'score';
          }
        }
        if (params.sort === 'senti') {
          this.myOrder = '-sentiment';
        }
        if (params.sort === '-senti') {
          this.myOrder = 'sentiment';
        }
        if (params.sort === 'abc') {
          this.myOrder = 'entity.name';
        }
        if (params.sort === '-abc') {
          this.myOrder = '-entity.name';
        }
        if (params.sort === 'trend') {
          this.myOrder = '-sentiment_delta';
        }
        if (params.sort === '-trend') {
          this.myOrder = 'sentiment_delta';
        }
        if (params.sort === 'count') {
          this.myOrder = '-count';
        }
        if (params.sort === '-count') {
          this.myOrder = 'count';
        }
        if (params.sort === 'ratio') {
          this.myOrder = '-volume_ratio';
        }
        if (params.sort === '-ratio') {
          this.myOrder = 'volume_ratio';
        }
      } else {
        if (!(auth.scorelabSettings.defaultScore === 'sentiment')) {
          this.myOrder = 'score';
        } else {
          this.myOrder = 'sentiment';
        }
      }
      if (
        this.config.appConfig.setup.useStorage &&
        localStorage.getItem('portfolio_view') !== null
      ) {
        this.portfolioView = localStorage.getItem('portfolio_view');
      } else {
        this.portfolioView = 'list';
      }
      if (
        params.id &&
        routing.reFresh(params, this.previousValue, [
          'id',
          'update',
          'time',
          'lang',
          'feed',
          'categories',
          'continents',
          'countries',
          'factuality',
          'temporality',
          'ranks',
        ])
      ) {
        // abort unwanted HTTP requests
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.ngUnsubscribe = new Subject();

        this.items = null;
        this.itemsLoaded = false;
        this.yukkApi
          .portfolio(params, this.auth.folio.content)
          .subscribe((result) => {
            this.items = result.treemap.children;
            this.items.forEach((item) => {
              item.entity.name =
                item.entity.name !== 'PNE'
                  ? item.entity.name
                  : 'PNE: ' +
                    item.entity.alpha_id.split('.')[1] +
                    ' (' +
                    item.entity.alpha_id.split('.')[0] +
                    ')';
            });
            this.itemsFiltered = this.items.map((item) => {
              return {
                id: item.entity.alpha_id,
                type: item.entity.type,
                name: item.entity.name,
                sentiment: item.sentiment,
                trend: item.sentiment_delta,
                count: item.count,
                ratio: item.volume_ratio,
              };
            });
            this.itemsCountMax = Math.max(
              ...this.items.map((item) => {
                return item.count;
              }),
              1,
            );
            this.itemsCountMin = Math.min(
              ...this.items.map((item) => {
                return item.count;
              }),
              0,
            );
            this.itemsTrendMax = Math.max(
              ...this.items.map((item) => {
                return item.sentiment_delta;
              }),
            );
            this.itemsTrendMin = Math.min(
              ...this.items.map((item) => {
                return item.sentiment_delta;
              }),
            );
            this.itemsRatioMax = Math.max(
              ...this.items.map((item) => {
                return item.volume_ratio;
              }),
            );
            this.itemsRatioMin = Math.min(
              ...this.items.map((item) => {
                return item.volume_ratio;
              }),
            );
            const itemsLength = this.items.length;
            if (
              !(auth.scorelabSettings.defaultScore === 'sentiment') &&
              itemsLength > 0
            ) {
              const data = new Array(itemsLength);
              const params = JSON.parse(JSON.stringify(this.params));
              if (!params.time) {
                params['time'] = '7';
              }
              const requestsList = [];
              const chunkSize = 100;
              const content = this.auth.folio ? this.auth.folio.content : [];
              for (let i = 0; i < content.length; i += chunkSize) {
                requestsList.push(
                  this.yukkApi
                    .scoresTimeSeries(
                      'score',
                      Object.assign({}, params, {
                        scoreForPortfolio: true,
                        itemized: true,
                        custom_payload: content.slice(i, i + chunkSize),
                      }),
                      'chart',
                    )
                    .pipe(catchError(() => of(undefined))),
                );
              }
              // const observables = (auth.scorelabSettings.defaultScore === 'credit_risk') ? (concat(...requestsList).pipe(toArray())) : (forkJoin(requestsList));
              concat(...requestsList)
                .pipe(toArray())
                .pipe(takeUntil(this.ngUnsubscribe))
                .subscribe((result3) => {
                  // @ts-expect-error This error is expected because the 'result3' variable is of type 'unknown[]' and does not have a 'flat' method.
                  const result2 = result3.flat();
                  result2.forEach((el, index) => {
                    this.items[index]['score'] =
                      el && el.score_ts
                        ? el.score_ts[el.score_ts.length - 1].score
                        : null;
                    if (['bb'].includes(auth.scorelabSettings.defaultScore)) {
                      this.items[index]['trend_type'] =
                        el && el.score_ts
                          ? el.score_ts[el.score_ts.length - 1].trend_type
                          : null;
                      this.items[index]['trend_value'] =
                        el && el.score_ts
                          ? el.score_ts[el.score_ts.length - 1].trend_value
                          : null;
                    }
                    data[index] =
                      el && el.score_ts
                        ? el.score_ts[el.score_ts.length - 1].score
                        : null;
                  });
                  this.items = data.map((el, index) => {
                    return Object.assign({}, this.items[index], {
                      score: el,
                    });
                  });
                  const definedItems = this.items.filter((item) => {
                    return item.score;
                  });
                  this.itemsScoreMax = Math.max(
                    ...definedItems.map((item) => {
                      return item.score;
                    }),
                    1,
                  );
                  this.itemsScoreMin = Math.min(
                    ...definedItems.map((item) => {
                      return item.score;
                    }),
                    0,
                  );
                  this.itemsLoaded = true;
                });
            }
          });
      }
      this.previousValue = params;
    });
  }

  ngOnDestroy() {
    // abort unwanted HTTP requests
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  getBackground(item) {
    if (this.myOrder === 'score' || this.myOrder === '-score') {
      const filterPipe =
        this.auth.getScoreType() === 'pos'
          ? new ColorScore2Pipe(this.routing)
          : new ColorScorePipe(this.routing);
      return filterPipe.transform(item.score);
    }
    if (this.myOrder === 'entity.name' || this.myOrder === '-entity.name') {
      const filterPipe = new ColorPipe(this.routing);
      return filterPipe.transform(item.sentiment);
    }
    if (this.myOrder === '-sentiment' || this.myOrder === 'sentiment') {
      const filterPipe = new ColorPipe(this.routing);
      return filterPipe.transform(item.sentiment);
    }
    if (
      this.myOrder === '-sentiment_delta' ||
      this.myOrder === 'sentiment_delta'
    ) {
      const filterPipe = new ColorPipe(this.routing);
      return filterPipe.transform(item.sentiment);
    }
    if (this.myOrder === '-count' || this.myOrder === 'count') {
      const filterPipe = new ColorRatioPipe(this.routing);
      return filterPipe.transform(
        item.volume_ratio,
        this.itemsRatioMin,
        this.itemsRatioMax,
      );
    }
    if (this.myOrder === '-volume_ratio' || this.myOrder === 'volume_ratio') {
      const filterPipe = new ColorRatioPipe(this.routing);
      let value = item.volume_ratio ? item.volume_ratio : undefined;
      if (
        (item.volume_ratio === null || item.volume_ratio === 0) &&
        item.count &&
        item.count > 0
      ) {
        value = this.itemsRatioMin;
      }
      return filterPipe.transform(
        value,
        this.itemsRatioMin,
        this.itemsRatioMax,
      );
    }
  }

  /**
   * edit the portfolio
   */
  editFolio() {
    this.dialog.open(PortfolioNameComponent, {
      data: {
        action: 'edit',
      },
    });
  }

  /**
   * create a new portfolio
   */
  newFolio() {
    this.dialog.open(PortfolioNameComponent, {
      data: {
        action: 'new',
      },
    });
  }

  /**
   * edit specific item
   */
  editIndex(entity) {
    this.dialog.open(PortfolioIndexComponent, {
      data: {
        // uid: this.params.folio,
        action: 'edit',
        entity: entity,
      },
    });
  }

  /**
   * add new item
   */
  newIndex() {
    this.dialog.open(PortfolioIndexComponent, {
      data: {
        // uid: this.params.folio,
        action: 'new',
      },
    });
  }

  /**
   * import by isin or name
   */
  newInport() {
    this.dialog.open(PortfolioImportComponent, {
      data: {
        // uid: this.params.folio,
      },
    });
  }

  /**
   * share portfolio with other users
   */
  iShare() {
    if (!this.auth.folio.sharing_mode) {
      this.dialog.open(PortfolioShareComponent, {
        data: {
          // uid: this.params.folio,
        },
      });
    }
  }

  /**
   * Import list of isins or portfolios from files
   */
  iImport(event, type) {
    if (event === null && type === 'isinsCreate') {
      this.dialog.open(PortfolioImportComponent, {
        data: {
          create: true,
          // uid: this.params.folio,
        },
      });
    } else if (event === null && type === 'isinsAdd') {
      this.dialog.open(PortfolioImportComponent, {
        data: {
          create: false,
          // uid: this.params.folio,
        },
      });
    } else if (event !== null && type === 'portfolio') {
      if (event.target.files && event.target.files[0]) {
        for (let i = 0; i < event.target.files.length; i++) {
          const reader = new FileReader();
          reader.onload = (e: any) => {
            const result = JSON.parse(e.target.result);
            if (
              this.auth.folio &&
              this.auth.folio.content.length + result.content.length >
                this.auth.featureFlags.entitiesPerPortfoliosLimit
            ) {
              this.snackBar.open(
                'Number of entities per portfolio is limited to ' +
                  this.auth.featureFlags.entitiesPerPortfoliosLimit +
                  '.',
                'OK',
                { duration: 5000 },
              );
            } else {
              this.auth.addEntity(result.content).subscribe((result2) => {
                this.router.navigate([], {
                  queryParams: {
                    id: result2.id,
                    update: Math.random(),
                  },
                  queryParamsHandling: 'merge',
                });
              });
            }
            // this.auth.newFolio(result.name).subscribe(result2 => {
            //   this.auth.addEntitiesToPortfolio(result.content, result2.id).subscribe(result3 => {
            //     this.router.navigate([], {
            //       queryParams: {
            //         id: result3.id,
            //         update: Math.random()
            //       }, queryParamsHandling: 'merge'
            //     });
            //   });
            // });
          };
          reader.readAsText(event.target.files[i]);
        }
      }
    }
  }

  /**
   * add tag to the url on item click
   */
  addTag(item) {
    if (!this.routing.isMobile()) {
      const tagItem = 'm:' + item.entity.type + ':' + item.entity.alpha_id;
      const tag = this.params.tag === tagItem ? null : encodeURI(tagItem);
      this.router.navigate([], {
        queryParams: {
          tag: tag,
          eventid: null,
          eventype: null,
          scoreevent: null,
          flagevent: null,
          newstype: null,
        },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      this.router.navigate(['/mobile/news/market/chart'], {
        queryParams: {
          type: item.entity.type,
          id: item.entity.alpha_id,
          tag: null,
          news: null,
          eventid: null,
          eventype: null,
          newstype: null,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  /**
   * redirect to the entity
   */
  iNavigate(item) {
    let path = '/cockpit/news/portfolio/chart';
    if (this.routing.isWidget()) {
      path = '/widget/chart';
    }
    if (this.routing.isMobile()) {
      path = '/mobile/news/portfolio/chart';
    }
    this.router.navigate([path], {
      queryParams: {
        type: item.entity.type,
        id: item.entity.alpha_id,
        portfolioId: this.auth.folio ? this.auth.folio.uid : null,
        tag: null,
        template: null,
        user: null,
        folio: null,
        folioupdate: null,
        period: null,
        news: null,
        focused_score: this.auth.scorelabSettings.defaultScore
          ? this.auth.scorelabSettings.defaultScore
          : null,
      },
      queryParamsHandling: 'merge',
    });
  }

  onMouseEnterName(id) {
    this.currentEntity = id;
  }

  onMouseLeaveName() {
    this.currentEntity = null;
  }

  /**
   * export as file xlsx
   */
  iExport(type) {
    /*if (type === 'events') {
      this.yukkApi.exportEvent(this.params, 'folio').subscribe(result => {
        FileSaver.saveAs(result, 'event_export.xlsx');
      });
    } else */
    if (type === 'portfolio') {
      if (this.auth.folio) {
        const portfolioPure = {
          name: this.auth.folio.name,
          content: this.auth.folio.content,
        };
        const portfolioString = JSON.stringify(portfolioPure);
        const blob = new Blob([portfolioString], { type: 'application/json' });
        FileSaver.saveAs(blob, 'portfolio_' + portfolioPure.name + '.json');
      }
    } else if (type === 'stats') {
      if (this.items && this.items.length > 0) {
        const date = new Date();
        const displayDate =
          date.getFullYear() +
          '-' +
          date.getMonth() +
          '-' +
          date.getDate() +
          ' ' +
          date.getHours() +
          '-' +
          date.getMinutes() +
          '-' +
          date.getSeconds();
        const doubleArrayData = [
          ['Name', 'Industry', 'CEO', 'Score', 'Sent', 'ΔSent', 'Vol', 'ΔVol'],
        ];
        this.items.forEach((company) => {
          doubleArrayData.push([
            company.entity && company.entity.name ? company.entity.name : '',
            company.entity &&
            company.entity.description &&
            company.entity.description.Industry &&
            company.entity.description.Industry.name
              ? company.entity.description.Industry.name
              : '',
            company.entity &&
            company.entity.description &&
            company.entity.description.CEO &&
            company.entity.description.CEO.name
              ? company.entity.description.CEO.name
              : '',
            company.score ? company.score : '',
            company.sentiment ? company.sentiment : '',
            company.sentiment_delta ? company.sentiment_delta : '',
            company.count ? company.count : '',
            company.volume_ratio ? company.volume_ratio : '',
          ]);
        });
        /* generate worksheet */
        const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(doubleArrayData);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(
          wb,
          'Portfolio statistics from ' + displayDate + '.xlsx',
        );
      }
    }
  }

  /**
   * apply chosen sorting option
   */
  goSort(value) {
    if (this.params.sort === value) {
      this.router.navigate([], {
        queryParams: { sort: '-' + value },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    } else {
      this.router.navigate([], {
        queryParams: { sort: value },
        queryParamsHandling: 'merge',
        replaceUrl: false,
      });
    }
  }

  /**
   * change main view layout of the portfolio board (mosaic or list)
   */
  changeView(type) {
    if (type === 'mosaic') {
      if (this.config.appConfig.setup.useStorage) {
        localStorage.setItem('portfolio_view', 'mosaic');
      }
      this.portfolioView = 'mosaic';
    } else if (type === 'list') {
      if (this.config.appConfig.setup.useStorage) {
        localStorage.setItem('portfolio_view', 'list');
      }
      this.portfolioView = 'list';
    }
  }

  /**
   * get tooltip from current default score
   */
  scoreTooltip() {
    return this.auth.getScoreName().full;
  }
}
