import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-article-card',
  templateUrl: './chat-article-cards.component.html',
  styleUrls: ['./chat-article-cards.component.scss'],
})
export class ArticleCardComponent implements OnInit {
  @Input() title: string;
  @Input() publishTime: string;
  @Input() provider: string;
  @Input() eventNames: string[];
  @Input() entityNames?: string[];
  @Input() citation: number;

  @Input() isTooltip: boolean;

  articleTitle: string;

  ngOnInit(): void {
    if (this.isTooltip) {
      this.articleTitle = this.title;
    } else {
      this.articleTitle =
        this.title?.length > 56 ? this.title.slice(0, 56) + '...' : this.title;
    }
  }
}
