<div class="ranking-table__main">
  <div class="loading" *ngIf="loading">
    <mat-spinner [diameter]="50"></mat-spinner>
  </div>
  <!-- <div class="nodata" *ngIf="nodata">NO DATA AVAILABLE</div> -->
  <ng-container *ngIf="!loading">
    <div class="ranking-table__titolo">
      <div class="ranking-table__box-trend">
        <div class="tito">
          <div class="cont">
            <span class="value" [class.sort-active]="true">
              <span class="sort">Entity </span>
            </span>
          </div>
        </div>
        <div class="info">
          <div class="cont">
            <span class="value" [class.sort-active]="true">
              <span class="sort">{{ childRankingScoreTitle }} </span>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="ranking-table__body">
      <div
        class="ranking-table__row"
        *ngFor="let data of rankingTableData; let i = index"
      >
        <div class="ranking-table__box-trend">
          <div class="tito">
            <div class="cont">
              <span class="value">{{ data.name }}</span>
            </div>
          </div>
          <div class="info">
            <div class="cont">
              <span
                class="value"
                [style.color]="getScoreColor(data)"
                *ngIf="data.scoreType !== 'sentiment'"
                >{{ data.score }}</span
              >
              <span
                class="value"
                [style.color]="getScoreColor(data)"
                *ngIf="data.scoreType === 'sentiment'"
                >{{ data.score | percent }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
