import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AIMessage, ChatMessage } from '../interfaces/chart-details.interface';
import * as moment from 'moment';

interface RecommendedChart {
  chart_type: string;
  entities: string | null;
  score_type: string | null;
  date_from: string | null;
  date_to: string | null;
}

@Component({
  selector: 'app-chat-charts',
  templateUrl: './chat-charts.component.html',
  styleUrls: ['./chat-charts.component.scss'],
})
export class ChatChartsComponent implements OnInit {
  @Input() childChat: ChatMessage;
  @Input() isVisible = true;
  @Output() visibilityChange = new EventEmitter<boolean>();

  public thisIsVisible: boolean = false;

  chat: ChatMessage;
  showRanking = false;
  showTrend = false;
  showEvents = false;
  showBenchmark = false;

  rankingScoreType: string | null = 'sentiment';
  rankingScoreTitle: string | null = 'Sentiment';
  trendScoreType: string | null = 'sentiment';
  eventsScoreType: string | null = 'sentiment';
  benchmarkScoreType: string | null = 'sentiment';

  rankingTimeframe: string | null = '7';
  trendTimeframe: string | null = '7';
  eventsTimeframe: string | null = '7';

  chartHeaderTitle: string | null = null;
  chartHeaderCompany: string | null = null;

  ngOnInit(): void {
    this.chat = this.childChat;
    this.processRecommendedCharts();
  }

  private processRecommendedCharts(): void {
    if (!this.isAIMessage(this.chat.message)) {
      return;
    }
    const recommendedCharts = this.chat.message
      .recommended_charts as RecommendedChart[];

    if (!recommendedCharts || !Array.isArray(recommendedCharts)) {
      console.error('Recommended charts data is invalid');
      return;
    }

    this.formatChartTitle(
      this.chat.message.requested_entities,
      recommendedCharts,
    );

    const rankingChart = this.findChartByType(recommendedCharts, 'Ranking');

    if (rankingChart && this.chat.message.requested_entities.length > 1) {
      this.displayRankingChart(rankingChart);
    } else {
      this.displayNonRankingCharts(recommendedCharts);
    }
  }

  private displayRankingChart(rankingChart: RecommendedChart): void {
    this.resetChartVisibility();
    this.showRanking = true;
    this.rankingScoreType = rankingChart.score_type || 'sentiment';
    this.rankingScoreTitle = this.getFormattedScoreName(
      rankingChart.score_type,
    );
    this.rankingTimeframe =
      rankingChart.date_from && rankingChart.date_to
        ? rankingChart.date_from +
          '--' +
          this.cockpitDateTo(rankingChart.date_to)
        : '7';
  }

  private displayNonRankingCharts(charts: RecommendedChart[]): void {
    this.resetChartVisibility();

    charts.forEach((chart) => {
      if (!chart.score_type) {
        chart.score_type = 'sentiment';
      }

      switch (chart.chart_type) {
        case 'Trend':
          this.showTrend = true;
          this.trendScoreType = chart.score_type;
          this.trendTimeframe =
            chart.date_from && chart.date_to
              ? chart.date_from + '--' + this.cockpitDateTo(chart.date_to)
              : '7';
          break;
        case 'Events':
          this.showEvents = true;
          this.eventsScoreType = chart.score_type;
          this.eventsTimeframe =
            chart.date_from && chart.date_to
              ? chart.date_from + '--' + this.cockpitDateTo(chart.date_to)
              : '7';
          break;
        case 'Benchmark':
          this.showBenchmark = true;
          this.benchmarkScoreType = chart.score_type;
          break;
        default:
          break;
      }
    });
  }

  private resetChartVisibility(): void {
    this.showRanking = false;
    this.showTrend = false;
    this.showEvents = false;
    this.showBenchmark = false;
  }

  private findChartByType(
    charts: RecommendedChart[],
    type: string,
  ): RecommendedChart | undefined {
    return charts.find((chart) => chart.chart_type === type);
  }

  closeCharts(): void {
    this.visibilityChange.emit(false);
  }

  openCharts(): void {
    this.visibilityChange.emit(true);
  }

  get showChartsContainer(): boolean {
    return (
      this.showRanking ||
      this.showTrend ||
      this.showEvents ||
      this.showBenchmark
    );
  }

  private formatChartTitle(
    entities: string[],
    recommendedCharts: RecommendedChart[],
  ): void {
    if (recommendedCharts.length === 0) return;

    const rankingChart = this.findChartByType(recommendedCharts, 'Ranking');
    if (entities.length > 1 && rankingChart) {
      this.chartHeaderTitle = `${this.getFormattedScoreName(rankingChart.score_type)} Ranking`;
      this.chartHeaderCompany = null;
    } else {
      recommendedCharts.forEach((chart) => {
        this.chartHeaderTitle = `${this.getFormattedScoreName(chart.score_type)} Score Analysis`;
      });
      this.chartHeaderCompany = this.isAIMessage(this.chat.message)
        ? this.chat.message.entityName
        : null;
    }
  }

  private getFormattedScoreName(scoreType: string | null): string {
    const scoreTypeMapping: { [key: string]: string } = {
      sentiment: 'Sentiment',
      general_risk: 'General Risk',
      credit_watch: 'Credit Risk',
      esg_risk: 'ESG Risk',
      esg: 'ESG',
      immediate_risk: 'Immediate Risk',
      bb: 'Bull-Bear',
    };

    return scoreTypeMapping[scoreType || ''] || 'Sentiment';
  }

  private isAIMessage(message: string | AIMessage): message is AIMessage {
    return (message as AIMessage).entityName !== undefined;
  }

  markFieldAsSeen(event): void {
    if (this.thisIsVisible === false && event === true) {
      this.thisIsVisible = true;
    }
  }

  cockpitDateTo(date) {
    return moment(date, 'YYYY-MM-DD').subtract(1, 'days').format('YYYY-MM-DD');
  }
}
