<div
  *ngIf="showChartsContainer"
  [ngClass]="{ hidden: !isVisible }"
  class="charts__container"
>
  <div
    class="charts__header--container"
    appDetectVisibility
    (isVisible)="markFieldAsSeen($event)"
  >
    <div class="charts__header">
      <div class="charts__title">
        <span
          *ngIf="chartHeaderCompany"
          class="charts__company"
          style="font-weight: 600"
        >
          {{ chartHeaderCompany }}, </span
        >{{ chartHeaderTitle }}
      </div>
    </div>
    <mat-icon
      class="charts__icon--close"
      id="chat-close-charts"
      (click)="closeCharts()"
      >close</mat-icon
    >
  </div>

  <div *ngIf="thisIsVisible">
    <app-chat-charts-trend
      *ngIf="showTrend"
      [childTrend]="chat.message.companyName"
      [childTrendScore]="trendScoreType"
      [childTrendTimeframe]="trendTimeframe"
    ></app-chat-charts-trend>
    <app-chat-charts-events
      *ngIf="showEvents"
      [childEvent]="chat.message.companyName"
      [childEventScore]="eventsScoreType"
      [childEventsTimeframe]="eventsTimeframe"
    ></app-chat-charts-events>
    <app-chat-charts-ranking
      *ngIf="showRanking"
      [childRankingScoreType]="rankingScoreType"
      [childRankingScoreTitle]="rankingScoreTitle"
      [chartEntities]="chat.message.requested_entities"
      [chartEntityNames]="chat.message.entityName"
      [childRankingTimeframe]="rankingTimeframe"
    ></app-chat-charts-ranking>
    <!-- <app-chat-charts-benchmark *ngIf="showEvents" [childBenchmark]="chat.message.companyName"></app-chat-charts-benchmark> -->
  </div>
</div>
